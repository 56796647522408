import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Grid, TextField } from "@material-ui/core";
import { CircularProgress } from "@mui/material";

import SenderIcon from "../../../assets/icons/send";
import { Box } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPaperclip } from "@fortawesome/free-solid-svg-icons";

import { myBucket } from "../../../util/s3";
import { toastify, toastType } from "util/toastify";

const AQChatFooter = ({ auth, oponents, roomId, socket }) => {
  const [message, setMessage] = useState("");
  const [typingStatus, setTypingStatus] = useState(null);
  const [lastEventTime, setLastEventTime] = useState(new Date());
  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [sending, setSending] = useState(false);
  const fileInputRef = React.useRef(null);

  useEffect(() => {
    const handler = (data) => {
      if (data.senderId !== auth.user.userId) {
        setTypingStatus(data.message);
        setLastEventTime(new Date());
      }
    };

    socket && socket.on("AQChatTypingResponse", handler);

    return () => socket.off("AQChatTypingResponse", handler);
  }, [auth.user.userId, socket]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const elapsedSeconds = (now.getTime() - lastEventTime.getTime()) / 1000;
      if (elapsedSeconds >= 1) {
        setTypingStatus(null);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lastEventTime]);

  const typingHandler = (e) => {
    setMessage(e.target.value);
    socket &&
      socket.emit("AQChatTypingMessage", {
        roomId,
        senderId: auth.user.userId,
        message: `${auth.user.username} is typing.`,
      });
  };

  const sendMessage = async () => {
    if (roomId) {
      if (message !== "" || files.length > 0) {
        setSending(true);
        const attachedFiles = await handleFileUpload();
        socket.emit("AQChatNewMessage", {
          roomId,
          senderId: auth.user.userId,
          receiverId: oponents.length > 0 ? oponents[0].id : null,
          text: message,
          attachedFiles,
        });
        setMessage("");
        setSending(false);
      } else {
        toastify("No content.", toastType.warning);
      }
    }
  };

  const handleFileChange = async (e) => {
    const newFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const newFile = new File([file], file.name, { type: file.type });
      newFiles.push(newFile);
    }

    setFiles([...files, ...newFiles]);

    const newPreviewUrls = await Promise.all(
      newFiles.map((file) => getImagePreviewUrl(file))
    );
    setPreviewUrls([...previewUrls, ...newPreviewUrls]);
  };

  const handleFileRemove = (file) => {
    const newFiles = files.filter((f) => f.name !== file.name);
    setFiles(newFiles);

    const newPreviewUrls = previewUrls.filter((url) => url !== file.previewUrl);
    setPreviewUrls(newPreviewUrls);
  };

  const handleFileUpload = async () => {
    const fileUploadPromises = files.map((file) => uploadFileToS3(file));
    const fileUploadResults = await Promise.all(fileUploadPromises);

    const fileNames = fileUploadResults.map((file) => file.Key);

    setPreviewUrls([]);
    setFiles([]);
    return fileNames;
  };

  const getFileBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        file.bufferData = resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const getImagePreviewUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        file.previewUrl = reader.result;
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const uploadFileToS3 = async (file) => {
    const timestamp = Date.now();
    const fileName = file.name.split('.').slice(0, -1).join('.');
    const fileExtension = file.name.split('.').pop();

    const fileKey = `${fileName}-${timestamp}.${fileExtension}`;
    const bufferData = await getFileBuffer(file);
    const params = {
      Bucket: "tradetribestore",
      Key: fileKey,
      Body: bufferData,
    };

    return myBucket.upload(params).promise();
  };

  return (
    <Grid container style={{ padding: "20px" }}>
      <Grid item xs={12}>
        <Box>
          {previewUrls.length > 0 && (
            <ul className="flex list-none pl-0 mb-0">
              {previewUrls.map(
                (previewUrl, index) =>
                  files[index].type.includes("image") && (
                    <li
                      key={`attached-${index}`}
                      className="relative mx-2 border-solid border-transparent border-2 rounded-xl overflow-hidden"
                    >
                      <img
                        src={previewUrl}
                        alt={`Preview ${index}`}
                        width={100}
                        height={100}
                      />
                      <button
                        className="absolute top-1 right-1 w-6 h-6 bg-[#F9F9F9CC] rounded-full p-0"
                        onClick={() => handleFileRemove(files[index])}
                      >
                        <FontAwesomeIcon
                          icon={faClose}
                          style={{ fontSize: 16 }}
                        />
                      </button>
                    </li>
                  )
              )}
            </ul>
          )}
        </Box>
        <Box>
          {previewUrls.length > 0 && (
            <ul className="flex list-none">
              {previewUrls.map(
                (previewUrl, index) =>
                  !files[index].type.includes("image") && (
                    <li
                      key={`attached-${index}`}
                      className="flex items-center mx-2 border-solid border-transparent border-2 rounded-xl overflow-hidden"
                    >
                      <h4 className="mb-0 mr-2">{files[index].name}</h4>
                      <button
                        className="w-8 h-8 bg-[#F9F9F9CC] rounded-full p-0"
                        onClick={() => handleFileRemove(files[index])}
                      >
                        X
                      </button>
                    </li>
                  )
              )}
            </ul>
          )}
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: "100%",
          }}
          className="relative flex items-center pr-24 pl-4 bg-[#f9f9f9] rounded-[20px]"
        >
          <TextField
            value={message}
            onChange={typingHandler}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                sendMessage();
              }
            }}
            id="fullWidth"
            label={typingStatus ? typingStatus : "Type here..."}
            fullWidth
            multiline
            maxRows={4}
            disabled={sending}
          />
          <button
            onClick={sendMessage}
            className="absolute flex items-center justify-center right-1 border-0 bg-main w-10 h-10 rounded-full focus:outline-none transition-colors duration-300 hover:bg-[#35f016]"
            disabled={sending}
          >
            {sending ? (
              <CircularProgress size={24} color="info" />
            ) : (
              <SenderIcon />
            )}
          </button>
          <button
            onClick={() => {
              fileInputRef.current.click();
            }}
            disabled={sending}
            className="absolute right-14 border-none bg-transparent focus:outline-none w-10 h-10 rounded-full transition-colors duration-300 focus:!bg-[#ddd]"
          >
            <FontAwesomeIcon
              icon={faPaperclip}
              style={{ fontSize: 20, color: "#000" }}
            />
          </button>
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            className="opacity-0 invisible d-none w-0"
            multiple
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(AQChatFooter);
