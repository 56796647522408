import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { Modal, Box, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const PdfViewer = ({ item, height }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [scale, setScale] = useState(1.0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const goToPrevPage = () => setPageNumber(pageNumber - 1);
  const goToNextPage = () => setPageNumber(pageNumber + 1);

  const zoomIn = () => setScale(scale + 0.2);
  const zoomOut = () => setScale(Math.max(0.8, scale - 0.2));

  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = item.url;
    link.download = item.name || "document.pdf"; // Use a default name if not provided
    link.click();
  };

  return (
    <div>
      <div onClick={handleOpen} style={{ cursor: "pointer" }}>
        <Document file={item.url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page height={height} renderTextLayer={false} pageNumber={1} />
        </Document>
      </div>

      <Modal open={open} onClose={handleClose} aria-labelledby="pdf-modal">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
            height: "90vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8, zIndex: 100 }}
          >
            <CloseIcon />
          </IconButton>

          <div className="w-full h-full overflow-auto">
            <Document file={item.url} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} scale={scale} />
            </Document>
          </div>

          {numPages && (
            <div
              style={{
                position: "absolute",
                bottom: 16,
                left: "50%",
                alignItems: "center",
                transform: "translateX(-50%)",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                padding: "8px 16px",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                zIndex: 101,
                display: "flex",
                gap: "8px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={pageNumber <= 1}
                onClick={goToPrevPage}
              >
                Previous
              </Button>
              <span>
                Page {pageNumber} of {numPages}
              </span>
              <Button
                variant="contained"
                color="primary"
                disabled={pageNumber >= numPages}
                onClick={goToNextPage}
              >
                Next
              </Button>
              <Button variant="outlined" onClick={zoomOut}>
                Zoom Out
              </Button>
              <Button variant="outlined" onClick={zoomIn}>
                Zoom In
              </Button>
              <Button variant="contained" color="secondary" onClick={downloadPdf}>
                Download
              </Button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default PdfViewer;
