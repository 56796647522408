import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ModalImage from "react-modal-image";
import { setCurrentUser } from "actions/authAction";
import aws from "aws-sdk";
import { useCallback } from "react";
import { useMemo } from "react";

const TDChatBody = ({
  auth,
  className,
  messages,
  socket,
  taskInfo,
  taskStatus,
  receiverAvatarLink,
  providerAvatarLink,
  setCurrentUser,
}) => {
  const [messageList, setMessageList] = useState([]);
  const list = useRef(null);

  const s3 = useMemo(() => new aws.S3(), []);

  const isImageFile = (file) => {
    if (file) {
      return (
        file.includes("jpg") ||
        file.includes("jpeg") ||
        file.includes("png") ||
        file.includes("gif") ||
        file.includes("svg") ||
        file.includes("webp")
      );
    } else {
      return false;
    }
  };

  const getFileLinks = useCallback(async () => {
    let tmp = [];
    for (let i = 0; i < messages.length; i++) {
      let attachedFiles = [];
      for (let j = 0; j < messages[i].attachedFiles.length; j++) {
        let params = {
          Bucket: "tradetribestore",
          Key: messages[i].attachedFiles[j].name,
        };
        const url = await s3.getSignedUrlPromise("getObject", params);
        attachedFiles.push(url);
      }
      tmp.push({ ...messages[i], attachedFiles });
    }
    setMessageList(
      tmp.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    );
  }, [messages, s3]);

  useEffect(() => {
    getFileLinks();
  }, [getFileLinks, messages]);

  useEffect(() => {
    const handler1 = async (data) => {
      console.log("handler1", data);
      let attachedFiles = data.attachedFiles;
      for (let i = 0; i < attachedFiles.length; i++) {
        let params = {
          Bucket: "tradetribestore",
          Key: attachedFiles[i],
        };
        attachedFiles[i] = await s3.getSignedUrlPromise("getObject", params);
      }
      setMessageList(
        [...messageList, { ...data, attachedFiles }].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      );
      list.current.scrollTop = list.current.scrollHeight;
    };
    socket && socket.on("TDChatMessageResponse", handler1);
    const handler2 = async (data) => {
      console.log("handler2", data);
      setMessageList(
        [...messageList, data].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      );
    };
    socket && socket.on("ServiceCancelRequest", handler2);
    const handler3 = async (data) => {
      console.log("handler3", data);
      setMessageList(
        [...messageList, data].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      );
    };
    socket && socket.on("ServiceCancelRejected", handler3);
    const handler4 = async (data) => {
      console.log("handler4", data);
      setMessageList(
        [...messageList, data].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      );
    };
    socket && socket.on("ServiceCancelAccepted", handler4);
    const handler5 = async (data) => {
      console.log("handler5", data);
      let attachedFiles = data.attachedFiles;
      for (let i = 0; i < attachedFiles.length; i++) {
        let params = {
          Bucket: "tradetribestore",
          Key: attachedFiles[i].name,
        };
        attachedFiles[i] = await s3.getSignedUrlPromise("getObject", params);
      }
      setMessageList(
        [...messageList, { ...data, attachedFiles }].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      );
    };
    socket && socket.on("ServiceCompleteRequest", handler5);
    const handler6 = async (data) => {
      console.log("handler6", data);
      setMessageList(
        [...messageList, data].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      );
    };
    socket && socket.on("ServiceCompleteRejected", handler6);
    const handler7 = async (data) => {
      console.log("handler7", data);
      setMessageList(
        [...messageList, data].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      );
    };
    socket && socket.on("ServiceCompleteAccepted", handler7);

    return () => {
      socket.off("TDChatMessageResponse", handler1);
      socket.off("ServiceCancelRequest", handler2);
      socket.off("ServiceCancelRejected", handler3);
      socket.off("ServiceCancelAccepted", handler4);
      socket.off("ServiceCompleteRequest", handler5);
      socket.off("ServiceCompleteRejected", handler6);
      socket.off("ServiceCompleteAccepted", handler7);
    };
  }, [socket, auth, messageList, s3, setCurrentUser]);

  useEffect(() => {
    list.current.scrollTop = list.current.scrollHeight;
  }, [messageList.length]);

  const rejectCancellation = async () => {
    if (taskInfo.receiver && taskInfo.provider) {
      socket.emit("SERVICECANCELREJECTED", {
        senderId: auth.user.userId,
        receiverId:
          taskInfo.receiver.id === auth.user.userId
            ? taskInfo.provider.id
            : taskInfo.receiver.id,
        taskId: taskInfo.id,
        message: `${auth.user.username} rejected the service cancellation.`,
      });
      toast.warning("Service cancellation request rejected", {
        position: "top-center",
        containerId: "main",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const acceptCancellation = async () => {
    if (taskInfo.receiver && taskInfo.provider) {
      socket.emit("SERVICECANCELACCEPTED", {
        senderId: auth.user.userId,
        receiverId:
          taskInfo.receiver.id === auth.user.userId
            ? taskInfo.provider.id
            : taskInfo.receiver.id,
        taskId: taskInfo.id,
        message: `${auth.user.username} accepted the service cancellation.`,
      });
      toast.warning("Service cancellation request accepted", {
        position: "top-center",
        containerId: "main",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const acceptComplete = async () => {
    if (taskInfo.receiver && taskInfo.provider) {
      socket.emit("SERVICECOMPLETEACCEPTED", {
        senderId: auth.user.userId,
        receiverId:
          taskInfo.receiver.id === auth.user.userId
            ? taskInfo.provider.id
            : taskInfo.receiver.id,
        taskId: taskInfo.id,
        text: `${auth.user.username} accepted the service completion.`,
      });
      toast.success("Complete Service request accepted", {
        position: "top-center",
        containerId: "main",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const rejectComplete = async () => {
    if (taskInfo.receiver && taskInfo.provider) {
      socket.emit("SERVICECOMPLETEREJECTED", {
        senderId: auth.user.userId,
        receiverId:
          taskInfo.receiver.id === auth.user.userId
            ? taskInfo.provider.id
            : taskInfo.receiver.id,
        taskId: taskInfo.id,
        text: `${auth.user.username} rejected the service completion.`,
      });
      toast.warning("Complete Service request rejected", {
        position: "top-center",
        containerId: "main",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const renderDialog = (message, index) => {
    if (taskInfo.provider && taskInfo.receiver) {
      switch (message.type) {
        case "NORMAL":
          return (
            <>
              <ListItem>
                <Grid className="mr-[10px]">
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-center mt-6 mb-3"
                  >
                    <Link to={`/user-profile/${message.senderId}`}>
                      <Avatar
                        alt="message sender avatar"
                        src={
                          message.senderId === taskInfo.provider.id
                            ? providerAvatarLink
                            : receiverAvatarLink
                        }
                      />
                    </Link>
                  </Stack>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <ListItemText
                      align="right"
                      secondary={
                        new Date(message.createdAt).getFullYear() ===
                        new Date().getFullYear()
                          ? new Date(message.createdAt).getMonth() ===
                            new Date().getMonth()
                            ? new Date(message.createdAt).getDate() ===
                              new Date().getDate()
                              ? new Date(message.createdAt).toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )
                              : `${new Date(
                                  message.createdAt
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })} ${new Date(
                                  message.createdAt
                                ).toLocaleDateString([], {
                                  day: "numeric",
                                })}`
                            : `${new Date(message.createdAt).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )} ${new Date(
                                message.createdAt
                              ).toLocaleDateString([], {
                                month: "short",
                                day: "numeric",
                              })}`
                          : `${new Date(message.createdAt).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )} ${new Date(message.createdAt).toLocaleDateString(
                              [],
                              {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              }
                            )}`
                      }
                    ></ListItemText>
                  </Grid>
                  <Grid item xs={12}>
                    <ListItemText
                      align="left"
                      primary={message.text}
                    ></ListItemText>
                  </Grid>
                </Grid>
              </ListItem>
              {message.attachedFiles && message.attachedFiles.length > 0 && (
                <ListItem>
                  <ul className="list-none px-3 rounded-xl mt-2 bg-[#F9F9F9]">
                    {message.attachedFiles.map((file, index) => (
                      <li
                        key={`message-${message.id}-attached-${index}`}
                        className="my-3"
                      >
                        {isImageFile(file) ? (
                          <div className="max-w-[240px]">
                            <ModalImage
                              small={file}
                              large={file}
                              alt={`Attached File ${index}`}
                            />
                          </div>
                        ) : (
                          <a href={file} download>
                            {file
                              ? file.split("?")[0].split("/").pop() || null
                              : null}
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                </ListItem>
              )}
              <Divider />
            </>
          );
        case "CANCELREQUESTED":
          return (
            <>
              <ListItem>
                <Grid className="mr-[10px]">
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-center mt-6 mb-3"
                  >
                    <Link to={`/user-profile/${message.senderId}`}>
                      <Avatar
                        alt="message sender avatar"
                        src={
                          message.senderId === taskInfo.provider.id
                            ? providerAvatarLink
                            : receiverAvatarLink
                        }
                      />
                    </Link>
                  </Stack>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <ListItemText
                      align="right"
                      secondary={
                        new Date(message.createdAt).getFullYear() ===
                        new Date().getFullYear()
                          ? new Date(message.createdAt).getMonth() ===
                            new Date().getMonth()
                            ? new Date(message.createdAt).getDate() ===
                              new Date().getDate()
                              ? new Date(message.createdAt).toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )
                              : `${new Date(
                                  message.createdAt
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })} ${new Date(
                                  message.createdAt
                                ).toLocaleDateString([], {
                                  day: "numeric",
                                })}`
                            : `${new Date(message.createdAt).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )} ${new Date(
                                message.createdAt
                              ).toLocaleDateString([], {
                                month: "short",
                                day: "numeric",
                              })}`
                          : `${new Date(message.createdAt).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )} ${new Date(message.createdAt).toLocaleDateString(
                              [],
                              {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              }
                            )}`
                      }
                    ></ListItemText>
                  </Grid>
                  <Grid item xs={12}>
                    <ListItemText
                      align="left"
                      primary={message.text}
                    ></ListItemText>
                  </Grid>
                  {index + 1 === messageList.length && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className="flex flex-row-reverse ml-auto"
                    >
                      <button
                        onClick={acceptCancellation}
                        className={
                          "inline-flex w-full mx-2 justify-center py-3 text-white text-center border-none bg-[#FF000E] rounded-full transition-colors duration-300 hover:bg-[#8a070d]" +
                          (message.senderId === auth.user.userId && " hidden")
                        }
                      >
                        Cancel Service
                      </button>
                      <button
                        onClick={rejectCancellation}
                        className="inline-flex w-full mx-2 justify-center py-3 text-white text-center border-none bg-black rounded-full transition-colors duration-300 hover:bg-[#242424]"
                      >
                        Don't Cancel
                      </button>
                    </Grid>
                  )}
                </Grid>
              </ListItem>
              <Divider />
            </>
          );
        case "CANCELACCEPTED":
          return (
            <>
              <ListItem>
                <Grid className="mr-[10px]">
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-center mt-6 mb-3"
                  >
                    <Link to={`/user-profile/${message.senderId}`}>
                      <Avatar
                        alt="message sender avatar"
                        src={
                          message.senderId === taskInfo.provider.id
                            ? providerAvatarLink
                            : receiverAvatarLink
                        }
                      />
                    </Link>
                  </Stack>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <ListItemText
                      align="right"
                      secondary={
                        new Date(message.createdAt).getFullYear() ===
                        new Date().getFullYear()
                          ? new Date(message.createdAt).getMonth() ===
                            new Date().getMonth()
                            ? new Date(message.createdAt).getDate() ===
                              new Date().getDate()
                              ? new Date(message.createdAt).toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )
                              : `${new Date(
                                  message.createdAt
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })} ${new Date(
                                  message.createdAt
                                ).toLocaleDateString([], {
                                  day: "numeric",
                                })}`
                            : `${new Date(message.createdAt).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )} ${new Date(
                                message.createdAt
                              ).toLocaleDateString([], {
                                month: "short",
                                day: "numeric",
                              })}`
                          : `${new Date(message.createdAt).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )} ${new Date(message.createdAt).toLocaleDateString(
                              [],
                              {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              }
                            )}`
                      }
                    ></ListItemText>
                  </Grid>
                  <Grid item xs={12}>
                    <ListItemText
                      align="left"
                      primary={message.text}
                    ></ListItemText>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
            </>
          );
        case "CANCELREJECTED":
          return (
            <>
              <ListItem>
                <Grid className="mr-[10px]">
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-center mt-6 mb-3"
                  >
                    <Link to={`/user-profile/${message.senderId}`}>
                      <Avatar
                        alt="message sender avatar"
                        src={
                          message.senderId === taskInfo.provider.id
                            ? providerAvatarLink
                            : receiverAvatarLink
                        }
                      />
                    </Link>
                  </Stack>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <ListItemText
                      align="right"
                      secondary={
                        new Date(message.createdAt).getFullYear() ===
                        new Date().getFullYear()
                          ? new Date(message.createdAt).getMonth() ===
                            new Date().getMonth()
                            ? new Date(message.createdAt).getDate() ===
                              new Date().getDate()
                              ? new Date(message.createdAt).toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )
                              : `${new Date(
                                  message.createdAt
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })} ${new Date(
                                  message.createdAt
                                ).toLocaleDateString([], {
                                  day: "numeric",
                                })}`
                            : `${new Date(message.createdAt).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )} ${new Date(
                                message.createdAt
                              ).toLocaleDateString([], {
                                month: "short",
                                day: "numeric",
                              })}`
                          : `${new Date(message.createdAt).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )} ${new Date(message.createdAt).toLocaleDateString(
                              [],
                              {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              }
                            )}`
                      }
                    ></ListItemText>
                  </Grid>
                  <Grid item xs={12}>
                    <ListItemText
                      align="left"
                      primary={message.text}
                    ></ListItemText>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
            </>
          );
        case "COMPLETEREQUESTED":
          return (
            <>
              <ListItem>
                <Grid className="mr-[10px]">
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-center mt-6 mb-3"
                  >
                    <Link to={`/user-profile/${message.senderId}`}>
                      <Avatar
                        alt="message sender avatar"
                        src={
                          message.senderId === taskInfo.provider.id
                            ? providerAvatarLink
                            : receiverAvatarLink
                        }
                      />
                    </Link>
                  </Stack>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <ListItemText
                      align="right"
                      secondary={
                        new Date(message.createdAt).getFullYear() ===
                        new Date().getFullYear()
                          ? new Date(message.createdAt).getMonth() ===
                            new Date().getMonth()
                            ? new Date(message.createdAt).getDate() ===
                              new Date().getDate()
                              ? new Date(message.createdAt).toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )
                              : `${new Date(
                                  message.createdAt
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })} ${new Date(
                                  message.createdAt
                                ).toLocaleDateString([], {
                                  day: "numeric",
                                })}`
                            : `${new Date(message.createdAt).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )} ${new Date(
                                message.createdAt
                              ).toLocaleDateString([], {
                                month: "short",
                                day: "numeric",
                              })}`
                          : `${new Date(message.createdAt).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )} ${new Date(message.createdAt).toLocaleDateString(
                              [],
                              {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              }
                            )}`
                      }
                    ></ListItemText>
                  </Grid>
                  <Grid item xs={12}>
                    <ListItemText
                      align="left"
                      primary={message.text}
                    ></ListItemText>
                  </Grid>
                </Grid>

                {index + 1 === messageList.length &&
                  taskStatus !== "COMPLETEACCEPTED" &&
                  taskStatus !== "REVIEWED" && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className="flex flex-row-reverse ml-auto"
                    >
                      <button
                        onClick={acceptComplete}
                        className={
                          "inline-flex w-full mx-2 justify-center py-3 text-white text-center border-none bg-main rounded-full transition-colors duration-300 hover:bg-[#0B4E00]" +
                          (message.senderId === auth.user.userId && " hidden")
                        }
                      >
                        Complete Service
                      </button>
                      <button
                        onClick={rejectComplete}
                        className="inline-flex w-full mx-2 justify-center py-3 text-white text-center border-none bg-black rounded-full transition-colors duration-300 hover:bg-[#242424]"
                      >
                        Cancel
                      </button>
                    </Grid>
                  )}
              </ListItem>
              {message.attachedFiles && message.attachedFiles.length > 0 && (
                <ListItem>
                  <ul className="list-none px-3 rounded-xl mt-2 bg-[#F9F9F9]">
                    {message.attachedFiles.map((file, index) => (
                      <li
                        key={`message-${message.id}-attached-${index}`}
                        className="my-3"
                      >
                        {isImageFile(file) ? (
                          <div className="max-w-[240px]">
                            <ModalImage
                              small={file}
                              large={file}
                              alt={`Attached File ${index}`}
                            />
                          </div>
                        ) : (
                          <a href={file} download>
                            {file
                              ? file.split("?")[0].split("/").pop() || null
                              : null}
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                </ListItem>
              )}
              <Divider />
            </>
          );
        case "COMPLETEACCEPTED":
          return (
            <>
              <ListItem>
                <Grid className="mr-[10px]">
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-center mt-6 mb-3"
                  >
                    <Link to={`/user-profile/${message.senderId}`}>
                      <Avatar
                        alt="message sender avatar"
                        src={
                          message.senderId === taskInfo.provider.id
                            ? providerAvatarLink
                            : receiverAvatarLink
                        }
                      />
                    </Link>
                  </Stack>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <ListItemText
                      align="right"
                      secondary={
                        new Date(message.createdAt).getFullYear() ===
                        new Date().getFullYear()
                          ? new Date(message.createdAt).getMonth() ===
                            new Date().getMonth()
                            ? new Date(message.createdAt).getDate() ===
                              new Date().getDate()
                              ? new Date(message.createdAt).toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )
                              : `${new Date(
                                  message.createdAt
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })} ${new Date(
                                  message.createdAt
                                ).toLocaleDateString([], {
                                  day: "numeric",
                                })}`
                            : `${new Date(message.createdAt).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )} ${new Date(
                                message.createdAt
                              ).toLocaleDateString([], {
                                month: "short",
                                day: "numeric",
                              })}`
                          : `${new Date(message.createdAt).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )} ${new Date(message.createdAt).toLocaleDateString(
                              [],
                              {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              }
                            )}`
                      }
                    ></ListItemText>
                  </Grid>
                  <Grid item xs={12}>
                    <ListItemText
                      align="left"
                      primary={message.text}
                    ></ListItemText>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
            </>
          );
        case "COMPLETEREJECTED":
          return (
            <>
              <ListItem>
                <Grid className="mr-[10px]">
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-center mt-6 mb-3"
                  >
                    <Link to={`/user-profile/${message.senderId}`}>
                      <Avatar
                        alt="message sender avatar"
                        src={
                          message.senderId === taskInfo.provider.id
                            ? providerAvatarLink
                            : receiverAvatarLink
                        }
                      />
                    </Link>
                  </Stack>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <ListItemText
                      align="right"
                      secondary={
                        new Date(message.createdAt).getFullYear() ===
                        new Date().getFullYear()
                          ? new Date(message.createdAt).getMonth() ===
                            new Date().getMonth()
                            ? new Date(message.createdAt).getDate() ===
                              new Date().getDate()
                              ? new Date(message.createdAt).toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )
                              : `${new Date(
                                  message.createdAt
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })} ${new Date(
                                  message.createdAt
                                ).toLocaleDateString([], {
                                  day: "numeric",
                                })}`
                            : `${new Date(message.createdAt).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )} ${new Date(
                                message.createdAt
                              ).toLocaleDateString([], {
                                month: "short",
                                day: "numeric",
                              })}`
                          : `${new Date(message.createdAt).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )} ${new Date(message.createdAt).toLocaleDateString(
                              [],
                              {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              }
                            )}`
                      }
                    ></ListItemText>
                  </Grid>
                  <Grid item xs={12}>
                    <ListItemText
                      align="left"
                      primary={message.text}
                    ></ListItemText>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
            </>
          );
        default:
          return <></>;
      }
    } else {
      return <></>;
    }
  };

  return (
    <List className={className + " scroll-container"} ref={list}>
      {messageList.length > 0 ? (
        <>
          {messageList.map((message, index) => (
            <React.Fragment key={`message-item-${message.id}`}>
              {renderDialog(message, index)}
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          <ListItem>
            <Grid className="mr-[10px]">
              <Stack
                direction="row"
                spacing={2}
                className="justify-center mt-6 mb-3"
              >
                {/* <Avatar alt="Jessica" src="/images/user.png" /> */}
              </Stack>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <ListItemText align="left" secondary=""></ListItemText>
              </Grid>
              <Grid item xs={12}>
                <ListItemText
                  align="left"
                  primary="No Message Yet"
                ></ListItemText>
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
        </>
      )}
    </List>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setCurrentUser })(TDChatBody);
