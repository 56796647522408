import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import aws from "aws-sdk";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import axiosClient from "../../api";
import AQChatHeader from "view/AskQuestion/components/AQChatHeader";
import AQChatBody from "view/AskQuestion/components/AQChatBody";
import AQChatFooter from "view/AskQuestion/components/AQChatFooter";
import { Avatar, Badge, Stack, styled } from "@mui/material";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    display: "block",
    width: "100%",
    maxWidth: "1065px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "50px",
    marginBottom: "100px",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "50vh",
    overflowY: "auto",
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    // backgroundColor: "#1CC400",
    // color: "#1CC400",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}));

const ChatHistory = ({ auth, socket }) => {
  const classes = useStyles();

  const [roomId, setRoomId] = useState();
  const [roomList, setRoomList] = useState([]);
  const [, setRoomInfo] = useState(null);
  const [messages, setMessages] = useState([]);
  const [oponents, setOponents] = useState([]);

  useEffect(() => {
    auth.user.userId &&
      axiosClient
        .post("api/chat/get-all-history", { userId: auth.user.userId })
        .then(async (res) => {
          const s3 = new aws.S3();

          let tmp = res.data.data.rooms;
          for (let i = 0; i < tmp.length; i++) {
            if (tmp[i].user && tmp[i].user.media) {
              let params = {
                Bucket: "tradetribestore",
                Key: tmp[i].user.media.url,
              };
              tmp[i].user.media.url = await s3.getSignedUrlPromise(
                "getObject",
                params
              );
            }
          }
          tmp && tmp.length > 0 && setRoomId(tmp[0].roomId);
          setRoomList(tmp);
        })
        .catch((error) => console.error(error));
  }, [auth.user.userId]);

  useEffect(() => {
    if (roomId) {
      axiosClient
        .post("api/chat/aq-chatroom-data", { roomId })
        .then((res) => {
          setRoomInfo(res.data.data.room);
          setMessages(res.data.data.room.messages);
          let tmp = res.data.data.room.members.filter(
            (member) => member.id !== auth.user.userId
          );
          setOponents(tmp);

          socket.emit("AQChatJoin", {
            roomId,
          });
        })
        .catch(() => console.error);
    }

    return () => {
      socket.emit("AQChatDisconnect", {
        roomId,
      });
    };
  }, [auth.user.userId, roomId, socket]);

  return (
    <main className="flex-1 chat-history px-4">
      <Grid container component={Paper} className={classes.chatSection}>
        <div className="border border-solid rounded-md">
          <div className="grid grid-cols-12">
            <div className="col-span-4 sm:col-span-3 border-y-0 border-l-0 border-r border-solid">
              {roomList.length > 0 ? (
                roomList.map((item, index) => (
                  <>
                    {item.user && (
                      <div
                        key={`notification-today-${index}`}
                        onClick={() => setRoomId(item.roomId)}
                        className="flex pl-3 items-center border-b border-solid border-t-0 border-x-0 border-gray-200"
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          className="justify-center mt-6 mb-3"
                        >
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            variant="dot"
                            color={
                              (new Date() - new Date(item.user.updatedAt)) /
                                (1000 * 60) <
                              5
                                ? "primary"
                                : "warning"
                            }
                          >
                            <Link to={`/user-profile/${item.user.id}`}>
                              <Avatar
                                alt={`${item.user.firstName} ${item.user.lastName}`}
                                src={`${
                                  item.user.media ? item.user.media.url : ""
                                }`}
                              />
                            </Link>
                          </StyledBadge>
                        </Stack>
                        <div className="ml-3 mt-[5px] text-start">
                          <div className="flex items-end cursor-pointer max-sm:text-sm">
                            {`${item.user.firstName} ${item.user.lastName}`}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))
              ) : (
                <p className="py-3 px-2">No history yet.</p>
              )}
            </div>
            <div className="col-span-8 sm:col-span-9">
              {oponents.length > 0 && <AQChatHeader oponents={oponents} />}
              <Divider />
              <AQChatBody
                classes={classes}
                socket={socket}
                messages={messages}
                setMessages={setMessages}
              />
              <Divider />
              <AQChatFooter
                socket={socket}
                roomId={roomId}
                oponents={oponents}
                messages={messages}
                setMessages={setMessages}
              />
            </div>
          </div>
        </div>
      </Grid>
    </main>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(ChatHistory);
