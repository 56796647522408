import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from "react-redux";
import aws from "aws-sdk";
import GoogleMapReact from "google-map-react";
import { TextField } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import HomeIcon from "@material-ui/icons/Home";
import CloseIcon from "@material-ui/icons/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faClose,
  faHeart as faHeartSolid,
  faLocationDot,
  faStar,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import axiosClient from "api";
import { usStates } from "util/usStates";
import {
  addToFavorites,
  removeFromFavorites,
} from "../../actions/favListAction";
import { setCurrentUser } from "../../actions/authAction";
import Review from "./component/review";
import { toastify, toastType } from "util/toastify";

import { Document, Page } from "react-pdf";
import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Button, Chip, Link } from "@mui/material";
import { useMemo } from "react";
import { useCallback } from "react";
import PdfViewer from "view/component/pdfViewer";

const style = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  textAlign: "center",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  paddingTop: "20px",
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingBottom: "40px",
  borderRadius: "20px",
};

const defaultProps = {
  center: {
    lat: 37.409375796794684,
    lng: -122.08710396476175,
  },
  zoom: 10,
};

const Marker = () => {
  return <HomeIcon />;
};

const UserProfile = ({
  auth,
  favourite,
  socket,
  addToFavorites,
  removeFromFavorites,
  setCurrentUser,
}) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [aqChatRoomId, setAQChatRoomId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [favouriteState, setFavouriteState] = useState(false);

  const [activeServiceId, setActiveServiceId] = useState(0);
  const [activeService, setActiveService] = useState(null);

  const [portfolioList, setPortfolioList] = useState([]);
  const [certificationList, setCertificationList] = useState([]);

  const [lightBoxImg, setLightBoxImg] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);

  const [requestServiceId, setRequestServiceId] = useState(0);
  const [requestServiceRate, setRequestServiceRate] = useState(0);
  const [customRate, setCustomRate] = useState(false);
  const [requestRequirement, setRequestRequirement] = useState("");
  const { id } = useParams();
  const history = useHistory();

  const s3 = useMemo(() => new aws.S3(), []);

  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const getImageLink = useCallback(
    async (key) => {
      let params = { Bucket: "tradetribestore", Key: key };
      return await s3.getSignedUrlPromise("getObject", params);
    },
    [s3]
  );

  useEffect(() => {
    if (favourite.list && favourite.list.includes(Number(id))) {
      setFavouriteState(true);
    }
  }, [favourite, id]);

  useEffect(() => {
    let isMounted = true;

    axiosClient
      .get(`/public/users/${id}`)
      .then(async (res) => {
        if (!isMounted) return;

        let tmp = { ...res.data.data.user };
        if (tmp && tmp.media) {
          tmp.media.url = await getImageLink(tmp.media.url);
        }
        if (isMounted) {
          setAQChatRoomId(res.data.data.aqChatRoomId);
          setUser({ ...tmp, totalStars: res.data.data.totalStars });
        }
      })
      .catch((err) => {
        if (isMounted) console.error(err);
      });

    return () => {
      isMounted = false;
    };
  }, [getImageLink, id]);

  useEffect(() => {
    if (user) {
      axiosClient
        .get(`/public/service/${user.services[activeServiceId].UserService.id}`)
        .then(async (res) => {
          setActiveService(res.data.data.data);
          if (res.data.data.data.certification) {
            let certifications = res.data.data.data.certification;
            let urls = [];
            for (let i = 0; i < certifications.length; i++) {
              if (certifications[i].media) {
                let params = {
                  Bucket: "tradetribestore",
                  Key: certifications[i].media.url,
                };
                let url = await s3.getSignedUrlPromise("getObject", params);

                urls.push({ url, title: certifications[i].desc });
              }
            }
            setCertificationList(urls);
          } else {
            setCertificationList([]);
          }
          if (res.data.data.data.portfolio) {
            let portfolio = res.data.data.data.portfolio;
            let urls = [];
            for (let i = 0; i < portfolio.length; i++) {
              if (portfolio[i].media) {
                let params = {
                  Bucket: "tradetribestore",
                  Key: portfolio[i].media.url,
                };
                let url = await s3.getSignedUrlPromise("getObject", params);
                urls.push({ url, title: portfolio[i].desc });
              }
            }
            setPortfolioList(urls);
          } else {
            setPortfolioList([]);
          }
        })
        .catch((err) => {
          console.error(err);
          toastify("Internal Server Error(s)", toastType.error);
        });
    }
  }, [activeServiceId, s3, user]);

  const getStateShortCode = (name) => {
    let val = usStates.find((state) => state.name === name.toUpperCase());
    return val ? val.abbreviation : name;
  };

  const askQuestionHandler = () => {
    if (aqChatRoomId) {
      history.push(`/ask-question?roomId=${aqChatRoomId}`);
    } else {
      axiosClient
        .get(`/api/chat/create-aq/${user.id}`)
        .then((res) => {
          history.push(`/ask-question?roomId=${res.data.data.roomId}`);
        })
        .catch((err) => {
          console.error(err);
          toastify("Internal Server Error(s)", toastType.error);
        });
    }
  };

  const toggleFavouriteHandler = (id) => {
    if (auth.isAuthenticated) {
      setLoading(true);

      let timer = setTimeout(() => {
        setLoading(false);
        if (favouriteState) {
          removeFromFavorites(id);
        } else {
          addToFavorites(id);
        }
        setFavouriteState(!favouriteState);
        return () => clearTimeout(timer);
      }, 1000);
    } else {
      toastify("Please sign in first.", toastType.warning);
    }
  };

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const handleLightboxClose = () => setLightBoxImg(null);
  const handleZoomIn = () => setZoomLevel((prev) => prev + 0.1);
  const handleZoomOut = () => setZoomLevel((prev) => Math.max(prev - 0.1, 0.1));

  const handleChange = (event) => {
    const id = Number(event.target.value);
    setRequestServiceId(id);
    if (id !== 0) {
      setRequestServiceRate(
        user.services.filter((item) => item.id === parseInt(id))[0].UserService
          .rate
      );
      setCustomRate(false);
    }
  };

  const changeRequirementHandler = (e) => {
    setRequestRequirement(e.target.value);
  };

  const sendOrderRequest = async () => {
    if (auth.user.userId === parseInt(id)) {
      toastify("It's you. Try with others.", toastType.warn);
    } else {
      setCurrentUser({
        ...auth.user,
        balance: auth.user.balance - Number(requestServiceRate),
      });
      if (auth.user.balance >= Number(requestServiceRate)) {
        axiosClient
          .post("/api/task/create-order", {
            serviceId: requestServiceId,
            providerId: Number(id),
            receiverId: auth.user.userId,
            rate: Number(requestServiceRate),
            requirement: requestRequirement,
            status: "REQUESTED",
          })
          .then((res) => {
            setCurrentUser({
              ...auth.user,
              balance: auth.user.balance - Number(requestServiceRate),
            });
            console.log("SERVICEREQUESTED", {
              senderId: auth.user.userId,
              receiverId: Number(id),
              title: user.services[activeServiceId].title,
              taskId: res.data.data.taskId,
            });
            socket &&
              socket.emit("SERVICEREQUESTED", {
                senderId: auth.user.userId,
                receiverId: Number(id),
                title: user.services[activeServiceId].title,
                taskId: res.data.data.taskId,
              });
            toastify("Service is Requested.", toastType.success);
            history.push(`/dashboard`);
          })
          .catch((err) => {
            toastify(
              "Failed to create order. Something went wrong. Please check your balance first.",
              toastType.error
            );
            console.error(err);
          });
      } else {
        toastify("Your balance is not enough.", toastType.warning);
      }
    }
  };

  const scrollToReviews = (e) => {
    e.preventDefault();
    document.querySelector("#review-section").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      <main className="flex-1 profile">
        <div className="container items-center justify-between p-[45px] bg-[#F9F9F9] rounded-[20px] mb-[30px] max-lg:block">
          {user && (
            <div className="flex items-center max-lg:mb-[20px]">
              <Stack direction="row" spacing={2} className="justify-center">
                <Link to={`/user-profile/${user.id}`}>
                  <Avatar
                    alt={`${user.firstName} ${user.lastName}`}
                    src={user.media ? user.media.url : ""}
                    className="min-w-[90px] min-h-[90px]"
                  />
                </Link>
              </Stack>
              <div className="ml-[23px]">
                <Link to={`/user-profile/${user.id}`}>
                  <h2 className="text-[25px]">{`${user.firstName} ${user.lastName}`}</h2>
                </Link>
                <div className="flex">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{ fontSize: 14, color: "#1CC400" }}
                  />
                  <p className="text-[15px] ml-[3px]">
                    {user.city ? user.city : "Los Angeles"},{" "}
                    {getStateShortCode(user.state ? user.state : "California")}
                  </p>
                </div>
                <div className="flex">
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ fontSize: 14, color: "#1CC400" }}
                  />
                  <p className="text-[15px] ml-[3px]">
                    Member since: {new Date(user.createdAt).getFullYear()}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="flex items-center max-sm:block">
            {activeService && (
              <div className="flex items-center">
                <img src="/images/icon.png" alt="icon" width="26" height="23" />
                <p className="text-[20px] ml-[3px]">
                  {activeService.rate} per task
                </p>
                {auth.user.userId !== Number(id) && (
                  <figure
                    onClick={() => toggleFavouriteHandler(Number(id))}
                    className="py-2 px-2.5 ml-[31px] cursor-pointer rounded-full bg-[#2C2C2C]"
                  >
                    <FontAwesomeIcon
                      icon={favouriteState ? faHeartSolid : faHeart}
                      flip={loading}
                      style={{ fontSize: 20, color: "white" }}
                    />
                  </figure>
                )}
              </div>
            )}
            {auth.isAuthenticated && auth.user.userId !== id && (
              <div className="flex max-sm:mt-6 max-sm:block">
                <button
                  type="button"
                  className="text-white py-3 px-8 ml-[13px] mr-[10px] !bg-[#2C2C2C] !border-[#2C2C2C] capitalize rounded-full transition-all duration-300 hover:!bg-[#414141] hover:!border-[#414141] max-sm:ml-0 max-sm:mb-4"
                  onClick={handleOpen}
                >
                  Request a Service
                </button>
                <button
                  type="button"
                  onClick={askQuestionHandler}
                  className="text-white py-3 px-8 !bg-second !border-second capitalize rounded-full transition-all duration-300 hover:!bg-main hover:!border-main"
                >
                  Ask a Question
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="container">
          <div className="row flex-1">
            <div className="grid grid-cols-12 w-full gap-8">
              {user && (
                <div className="side col-span-12 md:col-span-4 lg:col-span-3 mb-5 md:min-w-[250px] max-md:mb-6 max-sm:gap-0">
                  <div className="p-8 bg-[#f9f9f9] rounded-[20px] mb-3">
                    <h3 className="text-left">
                      {`${user.firstName} ${user.lastName}`}'s Services
                    </h3>
                    <div className={user.services.length > 0 ? "mt-4" : "mt-2"}>
                      {user &&
                        user.services.map((service, index) => (
                          <button
                            key={`user-service-${index}`}
                            type="button"
                            onClick={() => {
                              setActiveServiceId(index);
                            }}
                            className={
                              "group mb-[12px] relative flex items-center w-full py-1 rounded-full transition-color duration-300" +
                              (activeServiceId === index
                                ? "border-second bg-second hover:bg-main hover:border-main"
                                : "border-[#f0f0f0] bg-[#f0f0f0] hover:bg-[#ccc] hover:border-[#ccc]")
                            }
                          >
                            <span
                              className={
                                "flex-1 text-white" +
                                (activeServiceId === index
                                  ? ""
                                  : "group-hover:text-main")
                              }
                            >
                              {service.title} {service.type && "("}
                              {service.type?.toUpperCase()}
                              {service.type && ")"}
                            </span>
                            {activeServiceId === index && (
                              <FontAwesomeIcon
                                icon={faAngleRight}
                                style={{ fontSize: 20, color: "white" }}
                                className="top-1/2 right-1 -translate-y-1/2"
                              />
                            )}
                          </button>
                        ))}
                    </div>
                  </div>
                  {((user.lat && user.lng) || (user.state && user.city)) && (
                    <div className="pt-[28px] px-[19px] pb-[21px] bg-[#f9f9f9] rounded-[20px]">
                      <h3>{`${user.firstName} ${user.lastName}`}'s Location</h3>
                      {user.state && user.city && (
                        <p className="my-[12px] text-[15px]">
                          {user.city}, {getStateShortCode(user.state)}
                        </p>
                      )}
                      {user.lat && user.lng && (
                        <div className="h-40">
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                              language: "en",
                            }}
                            defaultCenter={defaultProps.center}
                            center={{
                              lat: user.lat
                                ? user.lat
                                : defaultProps.center.lat,
                              lng: user.lng
                                ? user.lng
                                : defaultProps.center.lng,
                            }}
                            defaultZoom={defaultProps.zoom}
                            draggable={false}
                          >
                            <Marker
                              lat={
                                user.lat ? user.lat : defaultProps.center.lat
                              }
                              lng={
                                user.lng ? user.lng : defaultProps.center.lng
                              }
                            />
                          </GoogleMapReact>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              {activeService ? (
                <div className="col-span-12 md:col-span-8 lg:col-span-9 pb-16 md:ml-10 md:pl-4">
                  <h2 className="flex items-center text-left mb-2">
                    {activeService.service.title}
                    {activeService.service.type && (
                      <Chip
                        label={activeService.service.type.toUpperCase()}
                        className="ml-2 text-sm"
                      />
                    )}
                  </h2>
                  <div className="flex items-center gap-2 mb-[22px]">
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{ fontSize: 18, color: "#1CC300" }}
                    />
                    <p>
                      <button onClick={scrollToReviews}>
                        {user.receivedReviews.length > 0
                          ? (
                              user.totalStars / user.receivedReviews.length
                            ).toFixed(1)
                          : 0}
                        ({user.receivedReviews.length} reviews)
                      </button>
                    </p>
                  </div>
                  <p className="pr-[20px] mb-[10px]">
                    {activeService.experience}
                  </p>
                  <p className="pr-[20px] pb-[40px] border-b border-x-0 border-t-0 border-solid border-[#e9e9e9]">
                    {activeService.detail}
                  </p>
                  {activeService.requireWorkSamples && (
                    <>
                      <h2 className="mt-[40px] mb-[23px]">Work Samples</h2>
                      <Swiper
                        className="border-b border-x-0 border-t-0 border-solid border-[#e9e9e9]"
                        modules={[Navigation]}
                        spaceBetween={15}
                        slidesPerView={4}
                        breakpoints={{
                          360: {
                            slidesPerView: 1,
                          },
                          640: {
                            slidesPerView: 2,
                          },
                          1024: {
                            slidesPerView: 4,
                          },
                        }}
                      >
                        {portfolioList.length > 0 ? (
                          <>
                            {portfolioList.map((item, index) => (
                              <SwiperSlide key={`portfolio-${index}`}>
                                {item.url.includes(".pdf") ? (
                                  <PdfViewer item={item} height={212} />
                                ) : (
                                  <img
                                    src={item.url}
                                    alt="work-1"
                                    width={212}
                                    height={212}
                                    className="w-full object-cover cursor-pointer"
                                    onClick={() => setLightBoxImg(item.url)}
                                  />
                                )}
                                <p className="pb-[40px] mt-[18px] text-[15px] font-medium">
                                  {item.title}
                                </p>
                              </SwiperSlide>
                            ))}
                          </>
                        ) : (
                          <SwiperSlide>
                            <img
                              src="/images/no-image.jpg"
                              alt="work-2"
                              className="w-full object-cover"
                            />
                            <p className="pb-[40px] mt-[18px] text-[15px] font-medium">
                              No Work Sample Added
                            </p>
                          </SwiperSlide>
                        )}
                      </Swiper>
                    </>
                  )}
                  {activeService.requireCertifications && (
                    <>
                      <h2 className="mt-[40px] mb-[23px]">
                        Professional Certifications
                      </h2>
                      <Swiper
                        className="border-b border-x-0 border-t-0 border-solid border-[#e9e9e9]"
                        modules={[Navigation]}
                        spaceBetween={15}
                        slidesPerView={4}
                        breakpoints={{
                          360: {
                            slidesPerView: 1,
                          },
                          640: {
                            slidesPerView: 2,
                          },
                          1024: {
                            slidesPerView: 4,
                          },
                        }}
                      >
                        {certificationList.length > 0 ? (
                          <>
                            {certificationList.map((item, index) => (
                              <SwiperSlide key={`certification-${index}`}>
                                {item.url.includes(".pdf") ? (
                                  <PdfViewer item={item} height={212} />
                                ) : (
                                  <img
                                    src={item.url}
                                    alt="work-1"
                                    width={212}
                                    height={212}
                                    className="w-full object-cover cursor-pointer"
                                    onClick={() => setLightBoxImg(item.url)}
                                  />
                                )}
                                <p className="pb-[40px] mt-[18px] text-[15px] font-medium">
                                  {item.title}
                                </p>
                              </SwiperSlide>
                            ))}
                          </>
                        ) : (
                          <>
                            <SwiperSlide>
                              <img
                                src="/images/no-image.jpg"
                                alt="work-2"
                                className="w-full object-cover"
                              />
                              <p className="pb-[40px] mt-[18px] text-[15px] font-medium">
                                No Certification Added
                              </p>
                            </SwiperSlide>
                          </>
                        )}
                      </Swiper>
                    </>
                  )}
                  <div id="review-section" className="flex my-[40px]">
                    <h2>Reviews</h2>
                    <div className="flex ml-[15px] items-center">
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ fontSize: 18, color: "#1CC300" }}
                      />
                      <p className="mb-[-3px]">
                        {user.receivedReviews.length > 0
                          ? (
                              user.totalStars / user.receivedReviews.length
                            ).toFixed(1)
                          : 0}
                        ({user.receivedReviews.length} reviews)
                      </p>
                    </div>
                  </div>
                  {user.receivedReviews.map((review, index) => (
                    <Review data={review} key={`review-${index}`} />
                  ))}
                </div>
              ) : (
                <div className="w-full p-8 bg-[#f9f9f9] rounded-[20px]">
                  <p>No service is selected.</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {user && (
          <div className="py-20 px-[195px] bg-[#0b4e0008] max-lg:px-[20px]">
            <h2 className="mb-[51px]">{`${user.firstName} ${user.lastName}`}</h2>
            <div className="flex items-start">
              <Stack
                direction="row"
                spacing={2}
                className="justify-center mr-[32px]"
              >
                <Link to={`/user-profile/${user.id}`}>
                  <Avatar
                    alt={`About ${user.firstName} ${user.lastName}`}
                    src={user.media ? user.media.url : ""}
                    className="min-w-[90px] min-h-[90px]"
                  />
                </Link>
              </Stack>
              <div className="desc relative">
                <div className="text-[20px] leading-10">
                  <div className="inline-block relative">
                    <img
                      src="/images/quote-1.png"
                      alt="quote-1"
                      width="36"
                      height="26"
                      className="absolute -top-6 -left-9"
                    />
                  </div>
                  {user.userBio}
                  <div className="inline-block relative">
                    <img
                      src="/images/quote-2.png"
                      alt="quote-2"
                      width="36"
                      height="26"
                      className="absolute -bottom-3 -right-9"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="max-md:max-w-[90vw]">
            {user && (
              <>
                <div className="relative flex justify-center mt-4">
                  <button
                    className="absolute -top-6 -right-6 outline-none w-8 h-8 rounded-full p-0"
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </button>

                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Request a Service from
                  </Typography>
                </div>
                <div className="flex items-center justify-center mt-[38px] mb-[27px]">
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-center mr-[10px]"
                  >
                    <Link to={`/user-profile/${user.id}`}>
                      <Avatar
                        alt={`${user.firstName} ${user.lastName}`}
                        src={user.media ? user.media.url : ""}
                      />
                    </Link>
                  </Stack>
                  <p className="font-semibold text-[17px]">{`${user.firstName} ${user.lastName}`}</p>
                </div>
                <form
                  action="#"
                  method="get"
                  className="flex flex-1 flex-col text-center"
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={requestServiceId}
                    className="text-left"
                    defaultValue={0}
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>Select a Service</MenuItem>
                    {user.services.map((item) => (
                      <MenuItem key={`service-item-${item.id}`} value={item.id}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                  <div className="relative my-3">
                    <input
                      className="flex flex-1 w-full py-2.5 pr-4 pl-4 border-none bg-[#e9e9e9] rounded-[13px] focus-visible:border-zinc-300 outline-zinc-300 !text-black max-sm:min-w-[260px]"
                      type="number"
                      name="point"
                      placeholder="Points for this service are"
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          setCustomRate(true);
                          setRequestServiceRate(e.target.value);
                        } else {
                          setCustomRate(false);
                          setRequestServiceRate(
                            user.services.filter(
                              (item) => item.id === requestServiceId
                            )[0].UserService.rate
                          );
                        }
                      }}
                      disabled={requestServiceId === 0}
                    ></input>
                    <button
                      className={
                        "btn btn-search absolute top-0 !opacity-100 right-0 text-white inline-block py-2 px-[30px] rounded-2xl " +
                        (customRate || requestServiceId === 0
                          ? "bg-[#ccc] text-[#999]"
                          : "bg-main text-white")
                      }
                      disabled={customRate || requestServiceId === 0}
                      type="button"
                    >
                      {user.services.filter(
                        (item) => item.id === requestServiceId
                      ).length > 0
                        ? user.services.filter(
                            (item) => item.id === requestServiceId
                          )[0].UserService.rate
                        : 0}
                    </button>
                  </div>
                  <TextField
                    style={{ width: "100%" }}
                    type="text"
                    label="Description of request"
                    variant="outlined"
                    multiline
                    onChange={changeRequirementHandler}
                    disabled={requestServiceId === 0}
                    minRows={5}
                    maxRows={5}
                  />
                  <button
                    className={
                      "py-3 px-7 mr-4 my-4 mx-auto max-w-[200px] border-none outline-none rounded-full focus:outline-none " +
                      (requestServiceId === 0 || requestRequirement === ""
                        ? "bg-[#E9E9E9] text-[#999]"
                        : "bg-main text-white")
                    }
                    type="button"
                    onClick={sendOrderRequest}
                    disabled={
                      requestServiceId === 0 || requestRequirement === ""
                    }
                  >
                    Send Request
                  </button>
                </form>
                <Typography
                  id="transition-modal-description"
                  className="text-[13px] text-center"
                >
                  Your points will be frozen until the service is completed.
                  Upon completion of service the points will transfer to the
                  provider upon mutual agreement.
                </Typography>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
      {lightBoxImg && (
        <div className="fixed flex flex-col justify-between items-center top-0 left-0 w-screen h-screen z-[100] bg-black bg-opacity-50">
          <div className="h-14 px-6 w-screen bg-stone-950 flex justify-end p-2 z-[101]">
            <button
              onClick={handleLightboxClose}
              className="flex justify-center items-center w-10 h-10 bg-transparent border-none cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faClose}
                style={{ fontSize: 20, color: "white" }}
              />
            </button>
          </div>
          <div
            className="flex flex-1 justify-center items-center"
            onClick={handleLightboxClose}
          >
            <img
              src={lightBoxImg}
              alt="LightBox"
              style={{ transform: `scale(${zoomLevel})` }}
              className="max-w-[80%] max-h-[80%]"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <div className="h-14 flex items-center space-x-4 pb-8">
            <Button
              onClick={handleZoomOut}
              color="primary"
              variant="contained"
              sx={{ color: "white" }}
            >
              Zoom Out
            </Button>
            <Button
              onClick={handleZoomIn}
              color="primary"
              variant="contained"
              sx={{ color: "white" }}
            >
              Zoom In
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  favourite: state.favourite,
});

export default connect(mapStateToProps, {
  addToFavorites,
  removeFromFavorites,
  setCurrentUser,
})(UserProfile);
