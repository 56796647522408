import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import aws from "aws-sdk";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  faBell,
  faHeart,
  faMessage,
} from "@fortawesome/free-regular-svg-icons";

import store from "../../../../store/store";
// import logo from "../../../../assets/images/logo.png";
import { logoutUser, setCurrentUser } from "actions/authAction";
import {
  setCurrentNotifications,
  addNewNotification,
} from "actions/notificationAction";
import axiosClient from "api";
import { setCurrentFavorites } from "actions/favListAction";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}));

const Header = ({
  auth,
  favourite,
  notification,
  socket,
  setCurrentUser,
  setCurrentNotifications,
  setCurrentFavorites,
}) => {
  const [showState, setShowState] = useState(true);
  const [avatarLink, setAvatarLink] = useState("");
  const [pushNotification, setPushNotification] = useState([]);
  const [todays, setTodays] = useState([]);
  const [thisMonth, setThisMonth] = useState([]);
  const [inThePast, setInThePast] = useState([]);
  const [numberOfNotifications, setNumberOfNotifications] = useState(0);
  const [flag, setFlag] = useState(true);
  const [userFlag, setUserFlag] = useState(false);
  const [socketFlag, setSocketFlag] = useState(true);

  const [searchBoxShowState, setSearchBoxShowState] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const headerSearch = useRef(null);
  const headerSearch1 = useRef(null);
  const searchBoxWrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      searchBoxWrapperRef.current &&
      !searchBoxWrapperRef.current.contains(event.target)
    ) {
      setSearchBoxShowState(false);
    }
  };

  function convertInput(input) {
    if (Array.isArray(input)) {
      return input;
    }
    if (typeof input === "string") {
      try {
        const parsed = JSON.parse(input);
        if (Array.isArray(parsed)) {
          return parsed;
        } else {
          return [];
        }
      } catch (e) {
        return [];
      }
    }
    return [];
  }

  useEffect(() => {
    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setPushNotification(notification.notifications);
    setNumberOfNotifications(
      notification.notifications.filter((item) => item.status === "UNREAD")
        .length
    );
  }, [notification]);

  useEffect(() => {
    socket &&
      socket.on("disconnect", async () => {
        await socket.io.reconnect();
        auth.user.userId &&
          socket.emit("Reconnection", {
            userId: auth.user.userId,
          });
      });
  }, [auth.user.userId, socket]);

  useEffect(() => {
    if (auth.user && auth.user.userId && !userFlag)
      axiosClient
        .get(`/api/users/${auth.user.userId}`)
        .then((res) => {
          setUserFlag(true);
          setCurrentUser(res.data.data.user);
          setCurrentFavorites(convertInput(res.data?.data?.user?.favList));
        })
        .catch((error) => console.error(error));
  }, [
    auth.user,
    auth.user.userId,
    setCurrentUser,
    setCurrentFavorites,
    userFlag,
  ]);

  useEffect(() => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/register" ||
      location.pathname === "/service"
    ) {
      setShowState(false);
    } else {
      setShowState(true);
    }
    window.scrollTo({ top: 0, left: 0 });
  }, [location.pathname]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const s3 = new aws.S3();

      if (auth.isAuthenticated && flag && s3) {
        let request;
        if (
          location.pathname === "/ask-question" ||
          location.pathname === "/task"
        ) {
          const roomId = new URLSearchParams(location.search).get("roomId");
          request = axiosClient.post("/api/notification/get-unread-only", {
            id: auth.user.userId,
            roomId,
          });
        } else {
          request = axiosClient.post("/api/notification/get-unread-only", {
            id: auth.user.userId,
          });
        }

        request
          .then(async (res) => {
            setFlag(false);
            const items = res.data.data.notifications.rows;
            let filtered = [];
            for (let i = 0; i < items.length; i++) {
              if (items[i].senderId !== auth.user.userId) {
                let tmp = { ...items[i] };
                if (tmp.sender && tmp.sender.media) {
                  let params = {
                    Bucket: "tradetribestore",
                    Key: tmp.sender.media.url,
                  };
                  tmp.sender.media.url = await s3.getSignedUrlPromise(
                    "getObject",
                    params
                  );
                }
                // Example notification creation time (replace with your own date)
                const createdAt = new Date(items[i].createdAt);

                // Calculate the time delta as milliseconds
                const deltaTime = Date.now() - createdAt.getTime();

                // Convert milliseconds to seconds, minutes, hours, days
                const deltaSeconds = Math.floor(deltaTime / 1000);
                const deltaMinutes = Math.floor(deltaSeconds / 60);
                const deltaHours = Math.floor(deltaMinutes / 60);
                const deltaDays = Math.floor(deltaHours / 24);

                // Format elapsed time string
                let elapsedTime;
                if (deltaDays > 0) {
                  elapsedTime = `${deltaDays} day${
                    deltaDays === 1 ? "" : "s"
                  } ago`;
                } else if (deltaHours > 0) {
                  elapsedTime = `${deltaHours} hour${
                    deltaHours === 1 ? "" : "s"
                  } ago`;
                } else if (deltaMinutes > 0) {
                  elapsedTime = `${deltaMinutes} minute${
                    deltaMinutes === 1 ? "" : "s"
                  } ago`;
                } else {
                  elapsedTime = `${deltaSeconds} second${
                    deltaSeconds === 1 ? "" : "s"
                  } ago`;
                }

                filtered.push({ elapsedTime, ...tmp });
              }
            }
            console.log("filetered length", filtered.length);
            setCurrentNotifications(
              filtered.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              )
            );
          })
          .catch((err) => {
            console.error(err);
          });
      }
    };

    fetchNotifications();
  }, [auth, flag, location.pathname, location.search, setCurrentNotifications]);

  useEffect(() => {
    const s3 = new aws.S3();
    let mounted = true; // Flag to track if component is mounted

    if (auth.user.avatar) {
      let params = {
        Bucket: "tradetribestore",
        Key: auth.user.avatar,
      };
      let promise = s3.getSignedUrlPromise("getObject", params);
      promise.then(
        (url) => {
          // Check if component is still mounted before updating state
          if (mounted) {
            setTimeout(() => {
              setAvatarLink(url);
            }, 3000);
          }
        },
        (err) => {
          console.error(err);
        }
      );
    }

    // Cleanup function to cancel async task if component unmounts
    return () => {
      mounted = false;
    };
  }, [auth.user.avatar]);

  useEffect(() => {
    if (auth.user && auth.user.userId && socketFlag && socket) {
      socket.emit("NewUser", {
        userId: auth.user.userId,
      });
      setSocketFlag(false);
    }
  }, [auth, socket, socketFlag]);

  useEffect(() => {
    const notificationHandler = async (data) => {
      const s3 = new aws.S3();
      let tmp = { ...data };
      if (data.sender && data.sender.media) {
        tmp.sender.media.url = await s3.getSignedUrlPromise("getObject", {
          Bucket: "tradetribestore",
          Key: data.sender.media.url,
        });
      }
      let list = [...pushNotification];
      list.push(tmp);
      setCurrentNotifications(
        list.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
    };

    const milestoneHandler = async (data) => {
      setCurrentUser({
        ...auth.user,
        balance: auth.user.balance + data.amount,
      });
    };

    if (socket && auth.isAuthenticated && auth.user) {
      socket.off("newNotification");
      socket.on("newNotification", notificationHandler);

      socket.off("cancelMilestone");
      socket.on("cancelMilestone", milestoneHandler);
    }
  }, [
    socket,
    auth,
    auth.user,
    auth.user.balance,
    pushNotification,
    numberOfNotifications,
    setCurrentUser,
    setCurrentNotifications,
  ]);

  useEffect(() => {
    if (pushNotification.length > 0) {
      let tmp1 = [],
        tmp2 = [],
        tmp3 = [];
      const today = new Date();
      pushNotification.forEach((item) => {
        let date = new Date(item.createdAt);
        if (
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear()
        ) {
          if (date.getDate() === today.getDate()) {
            tmp1.push(item);
          } else {
            tmp2.push(item);
          }
        } else {
          tmp3.push(item);
        }
      });
      setTodays(tmp1);
      setThisMonth(tmp2);
      setInThePast(tmp3);
    }
  }, [pushNotification]);

  const checkNotification = async (item) => {
    console.log(item);
    const s3 = new aws.S3();
    switch (item.type) {
      case "NEWMESSAGE":
        return axiosClient
          .post("/api/notification/mark-messages-seen", {
            type: item.type,
            roomId: item.roomId,
            receiverId: auth.user.userId,
          })
          .then(async (res) => {
            setFlag(false);
            const items = res.data.data.notifications.rows;
            let filtered = [];
            for (let i = 0; i < items.length; i++) {
              if (items[i].senderId !== auth.user.userId) {
                let tmp = { ...items[i] };
                if (tmp.sender && tmp.sender.media) {
                  let params = {
                    Bucket: "tradetribestore",
                    Key: tmp.sender.media.url,
                  };
                  tmp.sender.media.url = await s3.getSignedUrlPromise(
                    "getObject",
                    params
                  );
                }
                // Example notification creation time (replace with your own date)
                const createdAt = new Date(items[i].createdAt);

                // Calculate the time delta as milliseconds
                const deltaTime = Date.now() - createdAt.getTime();

                // Convert milliseconds to seconds, minutes, hours, days
                const deltaSeconds = Math.floor(deltaTime / 1000);
                const deltaMinutes = Math.floor(deltaSeconds / 60);
                const deltaHours = Math.floor(deltaMinutes / 60);
                const deltaDays = Math.floor(deltaHours / 24);

                // Format elapsed time string
                let elapsedTime;
                if (deltaDays > 0) {
                  elapsedTime = `${deltaDays} day${
                    deltaDays === 1 ? "" : "s"
                  } ago`;
                } else if (deltaHours > 0) {
                  elapsedTime = `${deltaHours} hour${
                    deltaHours === 1 ? "" : "s"
                  } ago`;
                } else if (deltaMinutes > 0) {
                  elapsedTime = `${deltaMinutes} minute${
                    deltaMinutes === 1 ? "" : "s"
                  } ago`;
                } else {
                  elapsedTime = `${deltaSeconds} second${
                    deltaSeconds === 1 ? "" : "s"
                  } ago`;
                }

                filtered.push({ elapsedTime, ...tmp });
              }
            }
            setTodays([]);
            setThisMonth([]);
            setInThePast([]);
            setCurrentNotifications(
              filtered.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              )
            );
            // setPushNotification(filtered);
            // setNumberOfNotifications(filtered.length);

            if (item.roomType === "AQ") {
              history.push(`/ask-question?roomId=${item.roomId}`);
            }
            if (item.roomType === "TD") {
              axiosClient
                .post("/api/task/get-task-id", {
                  roomId: item.roomId,
                  roomType: item.roomType,
                })
                .then((res) => {
                  history.push(`/task?taskId=${res.data.data.id}`);
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      case "SERVICEREQUESTED":
      case "SERVICEREJECTED":
      case "SERVICECOMPLETED":
      case "SERVICECANCELLED":
        return await axiosClient
          .post("/api/notification/mark-task-notification-seen", {
            id: item.id,
          })
          .then(async (res) => {
            setFlag(false);
            const items = res.data.data.notifications.rows;
            let filtered = [];
            for (let i = 0; i < items.length; i++) {
              if (items[i].senderId !== auth.user.userId) {
                let tmp = { ...items[i] };
                if (tmp.sender && tmp.sender.media) {
                  let params = {
                    Bucket: "tradetribestore",
                    Key: tmp.sender.media.url,
                  };
                  tmp.sender.media.url = await s3.getSignedUrlPromise(
                    "getObject",
                    params
                  );
                }
                // Example notification creation time (replace with your own date)
                const createdAt = new Date(items[i].createdAt);

                // Calculate the time delta as milliseconds
                const deltaTime = Date.now() - createdAt.getTime();

                // Convert milliseconds to seconds, minutes, hours, days
                const deltaSeconds = Math.floor(deltaTime / 1000);
                const deltaMinutes = Math.floor(deltaSeconds / 60);
                const deltaHours = Math.floor(deltaMinutes / 60);
                const deltaDays = Math.floor(deltaHours / 24);

                // Format elapsed time string
                let elapsedTime;
                if (deltaDays > 0) {
                  elapsedTime = `${deltaDays} day${
                    deltaDays === 1 ? "" : "s"
                  } ago`;
                } else if (deltaHours > 0) {
                  elapsedTime = `${deltaHours} hour${
                    deltaHours === 1 ? "" : "s"
                  } ago`;
                } else if (deltaMinutes > 0) {
                  elapsedTime = `${deltaMinutes} minute${
                    deltaMinutes === 1 ? "" : "s"
                  } ago`;
                } else {
                  elapsedTime = `${deltaSeconds} second${
                    deltaSeconds === 1 ? "" : "s"
                  } ago`;
                }

                filtered.push({ elapsedTime, ...tmp });
              }
            }
            setTodays([]);
            setThisMonth([]);
            setInThePast([]);
            setCurrentNotifications(
              filtered.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              )
            );
            history.push(`/dashboard?type=${item.type}`);
          })
          .catch((err) => {
            console.error(err);
          });
      case "ANNOUNCEMENT":
      case "SERVICEACCEPTED":
      case "SERVICECANCELREQUEST":
      case "SERVICECANCELREJECTED":
      case "SERVICECANCELACCEPTED":
      case "SERVICECOMPLETEREQUEST":
      case "SERVICECOMPLETEREJECTED":
      case "SERVICECOMPLETEACCEPTED":
      case "SERVICEREVIEWED":
        return await axiosClient
          .post("/api/notification/mark-task-notification-seen", {
            id: item.id,
          })
          .then(async (res) => {
            setFlag(false);
            const items = res.data.data.notifications.rows;
            let filtered = [];
            for (let i = 0; i < items.length; i++) {
              if (items[i].senderId !== auth.user.userId) {
                let tmp = { ...items[i] };
                if (tmp.sender && tmp.sender.media) {
                  let params = {
                    Bucket: "tradetribestore",
                    Key: tmp.sender.media.url,
                  };
                  tmp.sender.media.url = await s3.getSignedUrlPromise(
                    "getObject",
                    params
                  );
                }
                // Example notification creation time (replace with your own date)
                const createdAt = new Date(items[i].createdAt);

                // Calculate the time delta as milliseconds
                const deltaTime = Date.now() - createdAt.getTime();

                // Convert milliseconds to seconds, minutes, hours, days
                const deltaSeconds = Math.floor(deltaTime / 1000);
                const deltaMinutes = Math.floor(deltaSeconds / 60);
                const deltaHours = Math.floor(deltaMinutes / 60);
                const deltaDays = Math.floor(deltaHours / 24);

                // Format elapsed time string
                let elapsedTime;
                if (deltaDays > 0) {
                  elapsedTime = `${deltaDays} day${
                    deltaDays === 1 ? "" : "s"
                  } ago`;
                } else if (deltaHours > 0) {
                  elapsedTime = `${deltaHours} hour${
                    deltaHours === 1 ? "" : "s"
                  } ago`;
                } else if (deltaMinutes > 0) {
                  elapsedTime = `${deltaMinutes} minute${
                    deltaMinutes === 1 ? "" : "s"
                  } ago`;
                } else {
                  elapsedTime = `${deltaSeconds} second${
                    deltaSeconds === 1 ? "" : "s"
                  } ago`;
                }

                filtered.push({ elapsedTime, ...tmp });
              }
            }
            setTodays([]);
            setThisMonth([]);
            setInThePast([]);
            setCurrentNotifications(
              filtered.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              )
            );
            // setPushNotification(filtered);
            // setNumberOfNotifications(filtered.length);
            if (item.roomType === "TD") {
              axiosClient
                .post("/api/task/get-task-id", {
                  roomId: item.roomId,
                  roomType: item.roomType,
                })
                .then((res) => {
                  history.push(`/task?taskId=${res.data.data.id}`);
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      default:
        return null;
    }
  };

  const logoutHandler = () => {
    store.dispatch(logoutUser());
    history.push("/");
  };

  const markAllRead = () => {
    axiosClient
      .post("/api/notification/mark-all-read", {
        receiverId: auth.user.userId,
      })
      .then(async (res) => {
        const s3 = new aws.S3();
        setFlag(false);
        const items = res.data.data.notifications.rows;
        let filtered = [];
        for (let i = 0; i < items.length; i++) {
          if (items[i].senderId !== auth.user.userId) {
            let tmp = { ...items[i] };
            if (tmp.sender && tmp.sender.media) {
              let params = {
                Bucket: "tradetribestore",
                Key: tmp.sender.media.url,
              };
              tmp.sender.media.url = await s3.getSignedUrlPromise(
                "getObject",
                params
              );
            }
            // Example notification creation time (replace with your own date)
            const createdAt = new Date(items[i].createdAt);

            // Calculate the time delta as milliseconds
            const deltaTime = Date.now() - createdAt.getTime();

            // Convert milliseconds to seconds, minutes, hours, days
            const deltaSeconds = Math.floor(deltaTime / 1000);
            const deltaMinutes = Math.floor(deltaSeconds / 60);
            const deltaHours = Math.floor(deltaMinutes / 60);
            const deltaDays = Math.floor(deltaHours / 24);

            // Format elapsed time string
            let elapsedTime;
            if (deltaDays > 0) {
              elapsedTime = `${deltaDays} day${deltaDays === 1 ? "" : "s"} ago`;
            } else if (deltaHours > 0) {
              elapsedTime = `${deltaHours} hour${
                deltaHours === 1 ? "" : "s"
              } ago`;
            } else if (deltaMinutes > 0) {
              elapsedTime = `${deltaMinutes} minute${
                deltaMinutes === 1 ? "" : "s"
              } ago`;
            } else {
              elapsedTime = `${deltaSeconds} second${
                deltaSeconds === 1 ? "" : "s"
              } ago`;
            }

            filtered.push({ elapsedTime, ...tmp });
          }
        }
        console.log("filetered length", filtered.length);
        setCurrentNotifications(
          filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <header className="flex items-center py-10 min-h-[140px]">
      {auth.isAuthenticated ? (
        <div className="container justify-between max-md:block">
          <div className="relative header-left flex items-center max-md:mb-6 xl:mr-16">
            <Link to="/">
              <>
                <span className="text-3xl font-semibold tracking-wide text-[#1cc400]">
                  TRADE
                </span>
                <span className="text-3xl font-semibold tracking-wide text-black">
                  IT
                </span>
                <span className="text-3xl font-semibold tracking-wide text-[#1cc400]">
                  FWD
                </span>
                <sup className="absolute top-0 text-xs font-semibold text-[#1cc400]">
                  TM
                </sup>
              </>
            </Link>
          </div>
          {showState && (
            <div className="header-right flex items-center flex-1">
              {location.pathname !== "/single-service" ? (
                <>
                  <Link
                    to="/dashboard"
                    className="text-[#1cc400] text-xl ml-[41px] max-sm:ml-[15px] max-lg:hidden lg:ml-auto"
                  >
                    Dashboard
                  </Link>
                  <Link
                    to="/how-it-works"
                    className="text-[#1cc400] text-xl ml-6 max-lg:hidden"
                  >
                    How it Works
                  </Link>
                  <Link
                    to="/help"
                    className="text-[#1cc400] text-xl ml-6 xl:mr-8 max-lg:hidden"
                  >
                    Help
                  </Link>
                  <form className="relative flex flex-1 max-xl:hidden max-w-[500px]">
                    <input
                      ref={headerSearch}
                      className="flex flex-1 p-3 border-2 border-solid border-zinc-300 rounded-2xl focus-visible:border-zinc-300 outline-zinc-300 text-black max-sm:min-w-[260px]"
                      type="text"
                      name="search"
                      placeholder="What are you looking for?"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          headerSearch.current.blur();
                          localStorage.setItem(
                            "searchKeyword",
                            headerSearch.current.value
                          );
                          history.push("/single-service");
                        }
                      }}
                      required
                    ></input>
                    <button
                      onClick={() => {
                        localStorage.setItem(
                          "searchKeyword",
                          headerSearch.current.value
                        );
                        history.push("/single-service");
                      }}
                      className="btn btn-search absolute top-0.5 right-0.5 inline-block py-2.5 px-[30px] bg-second rounded-r-[14px] rounded-l-none transition-color duration-300 outline-none border-none hover:bg-main"
                      type="button"
                    >
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{ fontSize: 20, color: "white" }}
                      />
                    </button>
                  </form>
                </>
              ) : (
                <div className="flex-1" />
              )}
              <div className="relative xl:hidden flex z-1 transition-all duration-300 cursor-pointer max-lg:!ml-auto max-2xl:ml-0 ml-0">
                <div onClick={() => setSearchBoxShowState(!searchBoxShowState)}>
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{ fontSize: 23, color: "#000" }}
                    className="ml-[41px] max-md:ml-0"
                  />
                </div>
                {searchBoxShowState && (
                  <div
                    ref={searchBoxWrapperRef}
                    className="absolute left-0 top-full w-80"
                  >
                    <div className="mt-4">
                      <form className="relative flex flex-1">
                        <input
                          ref={headerSearch1}
                          className="flex flex-1 p-4 border-2 border-solid border-zinc-300 rounded-xl focus-visible:border-zinc-300 outline-zinc-300 text-black max-sm:min-w-[260px]"
                          type="text"
                          name="search1"
                          placeholder="What are you looking for?"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              headerSearch1.current.blur();
                              localStorage.setItem(
                                "searchKeyword",
                                headerSearch1.current.value
                              );
                              history.push("/single-service");
                            }
                          }}
                          required
                        ></input>
                        <button
                          onClick={() => {
                            localStorage.setItem(
                              "searchKeyword",
                              headerSearch1.current.value
                            );
                            history.push("/single-service");
                          }}
                          className="btn btn-search absolute top-0.5 right-0.5 inline-block py-3.5 px-4 bg-second rounded-r-[10px] rounded-l-none transition-color duration-300 hover:bg-main outline-none border-none"
                          type="button"
                        >
                          <FontAwesomeIcon
                            icon={faSearch}
                            style={{ fontSize: 20, color: "white" }}
                          />
                        </button>
                      </form>
                    </div>
                  </div>
                )}
              </div>
              <div className="group/first ml-[27px] flex relative z-1 transition-all duration-300 cursor-pointer">
                <Link to="/chat-history">
                  <FontAwesomeIcon
                    icon={faMessage}
                    style={{ fontSize: 23, color: "#000" }}
                    className=""
                  />
                </Link>
              </div>
              <div className="group/first ml-[27px] flex relative z-1 transition-all duration-300 cursor-pointer">
                <FontAwesomeIcon
                  icon={faBell}
                  style={{ fontSize: 23, color: "#000" }}
                  className=""
                />
                <span className="absolute -right-2 -top-2 flex items-center justify-center rounded-full px-1 text-xs text-[#fff] bg-main w-5 h-5">
                  {numberOfNotifications}
                </span>
                <div className="absolute opacity-0 invisible top-full pt-4 sm:-right-6 right-[-216px] bg-transparent group-hover/first:opacity-100 transition-all duration-0 group-hover/first:duration-300 border-none cursor-pointer w-full group-hover/first:z-20 group-hover/first:!visible min-w-[360px]">
                  <div className="px-6 py-6 bg-white shadow-md rounded-[20px]">
                    <div className="scroll-container relative max-h-80 overflow-y-scroll">
                      {todays.length > 0 && <h3 className="">Today</h3>}
                      {todays.map((item, index) => (
                        <>
                          {item.sender && (
                            <div
                              onClick={() => checkNotification(item)}
                              key={`notification-today-${index}`}
                              className="flex items-center border-b border-solid border-t-0 border-x-0 border-gray-200"
                            >
                              <Stack
                                direction="row"
                                spacing={2}
                                className="justify-center mt-6 mb-3"
                              >
                                <StyledBadge
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  color={
                                    (new Date() -
                                      new Date(item.sender.updatedAt)) /
                                      (1000 * 60) <
                                    5
                                      ? "primary"
                                      : "warning"
                                  }
                                  variant="dot"
                                >
                                  <Avatar
                                    alt={`${item.sender.firstName} ${item.sender.lastName}`}
                                    src={`${
                                      item.sender.media
                                        ? item.sender.media.url
                                        : ""
                                    }`}
                                  />
                                </StyledBadge>
                              </Stack>
                              <div className="ml-3 mt-[5px] text-start">
                                <div className="flex items-end">
                                  <p className="mr-2 font-bold text-[14px]">
                                    {`${item.sender.firstName} ${item.sender.lastName}`}
                                  </p>
                                  <p className="text-[13px]">{item.content}</p>
                                </div>
                                <span className="text-[12px] text-[#A0A1AB]">
                                  {item.elapsedTime}
                                </span>
                                {item.status === "UNREAD" && (
                                  <span className="text-white bg-red-500 text-xs px-1 py-0.5 rounded ml-2">
                                    UNREAD
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                      {thisMonth.length > 0 && (
                        <h3 className="mt-4">This Month</h3>
                      )}
                      {thisMonth.map((item, index) => (
                        <>
                          {item.sender && (
                            <div
                              onClick={() => checkNotification(item)}
                              key={`notification-this-month-${index}`}
                              className="flex items-center border-b border-solid border-t-0 border-x-0 border-gray-200"
                            >
                              <Stack
                                direction="row"
                                spacing={2}
                                className="justify-center mt-6 mb-3"
                              >
                                <StyledBadge
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  variant="dot"
                                  color={
                                    (new Date() -
                                      new Date(item.sender.updatedAt)) /
                                      (1000 * 60) <
                                    5
                                      ? "primary"
                                      : "warning"
                                  }
                                >
                                  <Avatar
                                    alt={`${item.sender.firstName} ${item.sender.lastName}`}
                                    src={`${
                                      item.sender.media
                                        ? item.sender.media.url
                                        : ""
                                    }`}
                                  />
                                </StyledBadge>
                              </Stack>
                              <div className="ml-3 mt-[5px] text-start">
                                <div className="flex items-end">
                                  <p className="mr-2 font-bold text-[14px]">
                                    {`${item.sender.firstName} ${item.sender.lastName}`}
                                  </p>
                                  <p className="text-[13px]">{item.content}</p>
                                </div>
                                <span className="text-[12px] text-[#A0A1AB]">
                                  {item.elapsedTime}
                                </span>
                                {item.status === "UNREAD" && (
                                  <span className="text-white bg-red-500 text-xs px-1 py-0.5 rounded ml-2">
                                    UNREAD
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                      {inThePast.length > 0 && <h3 className="mt-4">Others</h3>}
                      {inThePast.map((item, index) => (
                        <>
                          {item.sender && (
                            <div
                              onClick={() => checkNotification(item)}
                              key={`notification-this-month-${index}`}
                              className="flex items-center border-b border-solid border-t-0 border-x-0 border-gray-200"
                            >
                              <Stack
                                direction="row"
                                spacing={2}
                                className="justify-center mt-6 mb-3"
                              >
                                <StyledBadge
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  variant="dot"
                                  color={
                                    (new Date() -
                                      new Date(item.sender.updatedAt)) /
                                      (1000 * 60) <
                                    5
                                      ? "primary"
                                      : "warning"
                                  }
                                >
                                  <Avatar
                                    alt={`${item.sender.firstName} ${item.sender.lastName}`}
                                    src={`${
                                      item.sender.media
                                        ? item.sender.media.url
                                        : ""
                                    }`}
                                  />
                                </StyledBadge>
                              </Stack>
                              <div className="ml-3 mt-[5px] text-start">
                                <div className="flex items-end">
                                  <p className="mr-2 font-bold text-[14px]">
                                    {`${item.sender.firstName} ${item.sender.lastName}`}
                                  </p>
                                  <p className="text-[13px]">{item.content}</p>
                                </div>
                                <span className="text-[12px] text-[#A0A1AB]">
                                  {item.elapsedTime}
                                </span>
                                {item.status === "UNREAD" && (
                                  <span className="text-white bg-red-500 text-xs px-1 py-0.5 rounded ml-2">
                                    UNREAD
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                      {pushNotification.length < 1 && (
                        <div className="flex items-center">
                          <div className="ml-3 mt-[5px] text-start">
                            <div className="flex items-end">
                              <p className="mr-2 font-bold text-[14px]">
                                No New Notification
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {pushNotification.length > 0 && (
                      <div className="flex items-center mt-2 justify-center">
                        <button
                          onClick={markAllRead}
                          className="mr-2 font-bold text-[14px] border-none bg-transparent hover:text-blue-700"
                        >
                          Mark All Read
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="group/first ml-[24px] flex relative z-1 transition-all duration-300 cursor-pointer">
                <Link to="/favourite-list">
                  <FontAwesomeIcon
                    icon={faHeart}
                    style={{ fontSize: 23, color: "#000" }}
                    className=""
                  />
                </Link>
              </div>
              <div className="group/first flex relative py-[8px] pl-[10px] pr-[30px] ml-[36px] mr-[-20px] rounded-[20px] bg-main z-1 transition-all duration-300 cursor-pointer">
                <img
                  src="/images/wallet.png"
                  alt="wallet"
                  width="26"
                  height="23"
                />
                <span className="ml-[3px] text-white">
                  {auth.user && auth.user.balance ? auth.user.balance : 0}
                </span>
                <ul className="absolute min-w-fit opacity-0 invisible top-12 left-0 right-0 pl-0 list-none group-hover/first:opacity-100 transition-all duration-300 bg-[#f5f5f5] border border-solid cursor-pointer !border-main w-full group-hover/first:!visible group-hover/first:z-20">
                  <li className="group/second lg:hidden px-2 py-2 border-solid border-0 transition-all duration-300 hover:bg-main">
                    <Link
                      to="/dashboard"
                      className="!text-main group-hover/second:!text-white"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="group/second px-2 py-2 border-solid border-0 transition-all duration-300 hover:bg-main">
                    <Link
                      to="/my-profile"
                      className="!text-main group-hover/second:!text-white"
                    >
                      Profile
                    </Link>
                  </li>
                  <li className="group/second lg:hidden px-2 py-2 border-solid border-0 transition-all duration-300 hover:bg-main">
                    <Link
                      to="/how-it-works"
                      className="!text-main group-hover/second:!text-white whitespace-nowrap"
                    >
                      How It Works
                    </Link>
                  </li>
                  <li className="group/second lg:hidden px-2 py-2 border-solid border-0 transition-all duration-300 hover:bg-main">
                    <Link
                      to="/help"
                      className="!text-main group-hover/second:!text-white"
                    >
                      Help
                    </Link>
                  </li>
                  <li className="group/second px-2 py-2 transiton-all duration-300 hover:bg-main">
                    <span
                      onClick={() => logoutHandler()}
                      className="!text-main group-hover/second:!text-white"
                    >
                      Logout
                    </span>
                  </li>
                </ul>
              </div>
              <Stack
                direction="row"
                spacing={2}
                className="justify-center z-10 bg-gray-200 rounded-full"
              >
                <Avatar
                  alt="user avatar"
                  src={avatarLink}
                  className="min-w-[62px] min-h-[62px]"
                />
              </Stack>
            </div>
          )}
        </div>
      ) : (
        <div className="container justify-between max-md:block">
          <div className="relative header-left flex items-center max-md:mb-6">
            <Link to="/">
              <>
                <span className="text-3xl font-semibold tracking-wide text-[#1cc400]">
                  TRADE
                </span>
                <span className="text-3xl font-semibold tracking-wide text-black">
                  IT
                </span>
                <span className="text-3xl font-semibold tracking-wide text-[#1cc400]">
                  FWD
                </span>
                <sup className="absolute text-xs font-semibold text-[#1cc400]">
                  TM
                </sup>
              </>
            </Link>
          </div>
          {showState && (
            <div className="header-right flex items-center justify-end">
              <Link to="/how-it-works" className="max-sm:hidden">
                How it Works
              </Link>
              <Link to="/help" className="ml-6 mr-8 max-sm:hidden">
                Help
              </Link>
              <Link
                to="/login"
                className="text-second py-3 px-8 border border-solid !border-second bg-transparent capitalize rounded-full transition-all duration-300 hover:!bg-second hover:text-white"
              >
                Login
              </Link>
              <Link
                to="/register"
                className="!text-white py-3 px-8 ml-4 border border-solid !bg-second !border-second capitalize rounded-full !transition-all !duration-300 hover:!bg-main hover:!border-main"
              >
                Join Now
              </Link>
            </div>
          )}
          {showState && (
            <div className="flex items-center justify-center mt-6 sm:hidden">
              <Link to="/how-it-works" className="mx-4">
                How it Works
              </Link>
              <span className="text-2xl">/</span>
              <Link to="/help" className="mx-4">
                Help
              </Link>
            </div>
          )}
        </div>
      )}
    </header>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  favourite: state.favourite,
  notification: state.notification,
});

export default connect(mapStateToProps, {
  setCurrentUser,
  setCurrentNotifications,
  setCurrentFavorites,
  addNewNotification,
})(Header);
