import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Grid, Rating, TextField, Typography } from "@mui/material";
import SenderIcon from "assets/icons/send";
import { Box } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import slugify from "react-slugify";
import aws from "aws-sdk";

import { myBucket } from "../../../util/s3";
import { toastify, toastType } from "../../../util/toastify";
import { useCallback } from "react";
import { useMemo } from "react";

const TDChatFooter = ({
  auth,
  roomId,
  oponent,
  providerId,
  socket,
  taskStatus,
  taskId,
  defaultReview,
}) => {
  const [message, setMessage] = useState("");
  const [typingStatus, setTypingStatus] = useState(null);
  const [lastEventTime, setLastEventTime] = useState(new Date());
  const [review, setReview] = useState(defaultReview);
  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [sending, setSending] = useState(false);
  const fileInputRef = React.useRef(null);
  const [, setHover] = useState(-1);
  const [star, setStar] = useState(4);

  const s3 = useMemo(() => new aws.S3(), []);

  const reveiwData = useCallback(
    async (value) => {
      if (value && value.from && value.from.media) {
        let tmp = { ...value };
        const params = {
          Bucket: "tradetribestore",
          Key: value.from.media.url,
        };
        tmp.from.media.url = await s3.getSignedUrlPromise("getObject", params);
        setReview(tmp);
      }
    },
    [s3]
  );

  useEffect(() => {
    const handler1 = (data) => {
      if (data.senderId !== auth.user.userId) {
        setTypingStatus(data.message);
        setLastEventTime(new Date());
      }
    };

    const handler2 = async (data) => {
      if (data && data.from && data.from.media) {
        let tmp = { ...data };
        const params = {
          Bucket: "tradetribestore",
          Key: data.from.media.url,
        };
        tmp.from.media.url = await s3.getSignedUrlPromise("getObject", params);
      }
      setReview(data);
    };

    const handler3 = async (data) => {
      let tmp = { ...data };
      if (data && data.from && data.from.media) {
        const params = {
          Bucket: "tradetribestore",
          Key: data.from.media.url,
        };
        tmp.from.media.url = await s3.getSignedUrlPromise("getObject", params);
      }
      setReview(tmp);
    };

    socket && socket.on("TDChatTypingResponse", handler1);
    socket && socket.on("TDChatReviewResponse", handler2);
    socket && socket.on("ServiceReviewed", handler3);

    return () => {
      socket.off("TDChatTypingResponse", handler1);
      socket.off("TDChatReviewResponse", handler2);
    };
  }, [auth.user.userId, s3, socket]);

  useEffect(() => {
    reveiwData(defaultReview);
  }, [defaultReview, reveiwData]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const elapsedSeconds = (now.getTime() - lastEventTime.getTime()) / 1000;
      if (elapsedSeconds >= 1) {
        setTypingStatus(null);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lastEventTime]);

  const typingHandler = (e) => {
    setMessage(e.target.value);
    socket &&
      socket.emit("TDChatTypingMessage", {
        roomId,
        senderId: auth.user.userId,
        message: `${auth.user.username} is typing.`,
      });
  };

  const sendMessage = async () => {
    if (message !== "" || files.length > 0) {
      setSending(true);
      const attachedFiles = await handleFileUpload();
      socket.emit("TDChatNewMessage", {
        roomId,
        senderId: auth.user.userId,
        receiverId: oponent,
        text: message,
        attachedFiles,
      });
      setMessage("");
      setSending(false);
    } else {
      toastify("No content.", toastType.warning);
    }
  };

  const handleFileChange = async (e) => {
    // const newFiles = Array.from(e.target.files);
    const newFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const newFile = new File([file], file.name, { type: file.type });
      newFiles.push(newFile);
    }

    setFiles([...files, ...newFiles]);

    const newPreviewUrls = await Promise.all(
      newFiles.map((file) => getImagePreviewUrl(file))
    );
    setPreviewUrls([...previewUrls, ...newPreviewUrls]);
  };

  const handleFileRemove = (file) => {
    const newFiles = files.filter((f) => f.name !== file.name);
    setFiles(newFiles);

    const newPreviewUrls = previewUrls.filter((url) => url !== file.previewUrl);
    setPreviewUrls(newPreviewUrls);
  };

  const handleFileUpload = async () => {
    const fileUploadPromises = files.map((file) => uploadFileToS3(file));
    const fileUploadResults = await Promise.all(fileUploadPromises);

    const fileNames = fileUploadResults.map((file) => file.Key);

    setPreviewUrls([]);
    setFiles([]);
    return fileNames;
  };

  const getFileBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        file.bufferData = resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const getImagePreviewUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        file.previewUrl = reader.result;
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const uploadFileToS3 = async (file) => {
    const timestamp = Date.now();
    const fileName = file.name.split('.').slice(0, -1).join('.');
    const fileExtension = file.name.split('.').pop();

    const fileKey = `${fileName}-${timestamp}.${fileExtension}`;
    const bufferData = await getFileBuffer(file);
    const params = {
      Bucket: "tradetribestore",
      Key: fileKey,
      Body: bufferData,
    };

    return myBucket.upload(params).promise();
  };

  const submitReview = () => {
    if (message !== "" || files.length > 0) {
      setSending(true);
      socket.emit("SERVICEREVIEWED", {
        roomId,
        taskId,
        star,
        senderId: auth.user.userId,
        receiverId: oponent,
        text: message,
      });
      setMessage("");
      setSending(false);
    } else {
      toastify("No content.", toastType.warning);
    }
  };

  return (
    <Grid container style={{ padding: "20px" }}>
      <Grid item xs={12}>
        <Box>
          {previewUrls.length > 0 && (
            <ul className="flex list-none">
              {previewUrls.map(
                (previewUrl, index) =>
                  files[index].type.includes("image") && (
                    <li
                      key={`attached-${index}`}
                      className="relative mx-2 border-solid border-transparent border-2 rounded-xl overflow-hidden"
                    >
                      <img
                        src={previewUrl}
                        alt={`Preview ${index}`}
                        width={120}
                        height={120}
                      />
                      <button
                        className="absolute top-1 right-1 w-8 h-8 bg-[#F9F9F9CC] rounded-full p-0"
                        onClick={() => handleFileRemove(files[index])}
                      >
                        X
                      </button>
                    </li>
                  )
              )}
            </ul>
          )}
        </Box>
        <Box>
          {previewUrls.length > 0 && (
            <ul className="flex list-none">
              {previewUrls.map(
                (previewUrl, index) =>
                  !files[index].type.includes("image") && (
                    <li
                      key={`attached-${index}`}
                      className="flex items-center mx-2 border-solid border-transparent border-2 rounded-xl overflow-hidden"
                    >
                      <h4 className="mb-0 mr-2">{files[index].name}</h4>
                      <button
                        className="w-8 h-8 bg-[#F9F9F9CC] rounded-full p-0"
                        onClick={() => handleFileRemove(files[index])}
                      >
                        X
                      </button>
                    </li>
                  )
              )}
            </ul>
          )}
        </Box>
        {taskStatus === "COMPLETEACCEPTED" &&
          (auth.user.userId !== providerId ? (
            <>
              <Typography variant="h6" className="mb-4">
                Leave a Review
              </Typography>
              <Box className="flex mb-4 items-center">
                <Rating
                  size="large"
                  color="#1CC300"
                  name="hover-feedback1"
                  value={star}
                  onChange={(_event, newValue) => setStar(newValue)}
                  onChangeActive={(_event, newHover) => setHover(newHover)}
                />
                {star !== null && (
                  <Box ml={2}>{star.toFixed(1)} out of 5.0</Box>
                )}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                }}
                className="relative flex items-center pr-24 pl-4 mb-4 !border-0 bg-[#f9f9f9] rounded-[20px]"
              >
                <TextField
                  value={message}
                  onChange={typingHandler}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      submitReview();
                    }
                  }}
                  id="fullWidth"
                  label="Type your thoughts here..."
                  fullWidth
                  multiline
                  className="outline-none !border-0"
                  maxRows={4}
                  minRow={4}
                  disabled={sending}
                />
              </Box>
              <Box className="flex justify-end">
                <button
                  onClick={submitReview}
                  className="flex items-center justify-center right-1 border-0 h-12 px-6 text-white rounded-full focus:outline-none transition-colors duration-300 hover:bg-[#35f016] bg-[#22ac0a] focus:ring-2 focus:ring-"
                >
                  {sending ? (
                    <CircularProgress size={24} color="info" />
                  ) : (
                    <>Submit Review</>
                  )}
                </button>
              </Box>
            </>
          ) : (
            <Typography variant="h6" className="mb-4">
              Waiting for review.
            </Typography>
          ))}
        {taskStatus === "REVIEWED" && review && (
          <>
            <Typography variant="h6" className="mb-4">
              Reciever Left a {review.star ? review.star : 0} Star Review
            </Typography>
            <Box className="flex mb-4 items-center">
              <Rating
                size="large"
                color="#1CC300"
                name="hover-feedback1"
                value={review.star ? review.star : 0}
                readOnly
              />
              <Box ml={2}>
                {review.star ? review.star.toFixed(1) : 0.0} out of 5.0
              </Box>
            </Box>
            {review?.from && (
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                }}
                className="relative flex items-start py-8 pr-24 pl-4 mb-4 !border-0 bg-[#f9f9f9] rounded-[20px]"
              >
                <Avatar
                  alt="Review Sender Avatar"
                  src={review?.from?.media ? review.from.media.url : ""}
                  className="mr-2"
                />
                <Box className="flex flex-col">
                  <Typography variant="h6" className="mb-2">
                    {review?.from?.firstName || ""}{" "}
                    {review?.from?.lastName || ""}
                  </Typography>
                  <Typography variant="h7">{review.desc}</Typography>
                </Box>
              </Box>
            )}
          </>
        )}
        {taskStatus !== "COMPLETEACCEPTED" && taskStatus !== "REVIEWED" && (
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
            }}
            className="relative flex items-center pr-24 pl-4 !border-0 bg-[#f9f9f9] rounded-[20px]"
          >
            <TextField
              value={message}
              onChange={typingHandler}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  sendMessage();
                }
              }}
              id="fullWidth"
              label={typingStatus ? typingStatus : "Type here..."}
              fullWidth
              multiline
              className={`outline-none !border-0 ${
                sending || ["CANCELTASK", "COMPLETETASK"].includes(taskStatus)
                  ? "cursor-not-allowed"
                  : ""
              }`}
              maxRows={4}
              disabled={
                sending || ["CANCELTASK", "COMPLETETASK"].includes(taskStatus)
              }
            />
            <button
              onClick={sendMessage}
              className={`absolute flex items-center justify-center right-1 border-0 w-10 h-10 rounded-full focus:outline-none transition-colors duration-300 bg-[#35f016] hover:bg-[#22ac0a] focus:ring-2 ${
                sending || ["CANCELTASK", "COMPLETETASK"].includes(taskStatus)
                  ? "pointer-events-none"
                  : "cursor-pointer"
              }`}
              disabled={
                sending || ["CANCELTASK", "COMPLETETASK"].includes(taskStatus)
              }
            >
              {sending ? (
                <CircularProgress size={24} color="info" />
              ) : (
                <SenderIcon />
              )}
            </button>
            <button
              onClick={() => {
                fileInputRef.current.click();
              }}
              disabled={
                sending || ["CANCELTASK", "COMPLETETASK"].includes(taskStatus)
              }
              className={`absolute right-14 border-none bg-transparent focus:outline-none w-10 h-10 rounded-full transition-colors duration-300 hover:!bg-[#e2e2e2] ${
                sending || ["CANCELTASK", "COMPLETETASK"].includes(taskStatus)
                  ? "pointer-events-none"
                  : "cursor-pointer"
              }`}
            >
              <FontAwesomeIcon
                icon={faPaperclip}
                style={{ fontSize: 20, color: "#2c2c2c", zIndex: 10 }}
              />
            </button>
            <input
              ref={fileInputRef}
              type="file"
              onChange={handleFileChange}
              className="opacity-0 invisible d-none w-0"
              multiple
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(TDChatFooter);
