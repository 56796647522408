import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { connect } from "react-redux";
import aws from "aws-sdk";
import ModalImage from "react-modal-image";
import { Grid, List, ListItem, ListItemText } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import axiosClient from "api";
import PdfViewer from "view/component/pdfViewer";

const AQChatBody = ({ auth, classes, messages, socket }) => {
  const [messageList, setMessageList] = useState([]);
  const list = useRef(null);

  const s3 = useMemo(() => new aws.S3(), []);

  const getFileLinks = useCallback(async () => {
    let tmp = [];
    for (let i = 0; i < messages.length; i++) {
      let attachedFiles = [];
      for (let j = 0; j < messages[i].attachedFiles.length; j++) {
        let params = {
          Bucket: "tradetribestore",
          Key: messages[i].attachedFiles[j].name,
        };
        const url = await s3.getSignedUrlPromise("getObject", params);
        attachedFiles.push(url);
      }
      tmp.push({ ...messages[i], attachedFiles });
    }
    setMessageList(
      tmp.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    );
  }, [messages, s3]);

  const isImageFile = (file) => {
    if (file) {
      return (
        file.includes("jpg") ||
        file.includes("jpeg") ||
        file.includes("png") ||
        file.includes("gif") ||
        file.includes("svg") ||
        file.includes("webp")
      );
    } else {
      return false;
    }
  };

  useEffect(() => {
    getFileLinks();
  }, [getFileLinks, messages]);

  useEffect(() => {
    const handler = async (data) => {
      console.log(data);
      let attachedFiles = data.attachedFiles;
      for (let i = 0; i < attachedFiles.length; i++) {
        let params = {
          Bucket: "tradetribestore",
          Key: attachedFiles[i],
        };
        attachedFiles[i] = await s3.getSignedUrlPromise("getObject", params);
      }
      setMessageList(
        [...messageList, { ...data, attachedFiles }].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      );
    };
    socket && socket.on("AQChatMessageResponse", handler);
    list.current.scrollTop = list.current.scrollHeight;

    return () => {
      socket.off("AQChatMessageResponse", handler);
    };
  }, [socket, messageList, s3]);

  return (
    <List className={classes.messageArea + " scroll-container"} ref={list}>
      {messageList.length > 0 ? (
        <>
          {messageList.map((message) => (
            <ListItem
              key={`message-item-${message.id}`}
              className="flex flex-col-reverse"
            >
              {auth.user.userId === message.senderId ? (
                <>
                  <Grid className="send-msg" container>
                    {message.attachedFiles &&
                      message.attachedFiles.length > 0 && (
                        <div className="flex items-center group">
                          <ul className="list-none px-3 rounded-xl mt-2 bg-main">
                            {message.attachedFiles.map((file, index) => (
                              <li
                                key={`message-${message.id}-attached-${index}`}
                                className="my-3"
                              >
                                {file.includes("pdf") ? (
                                  <PdfViewer
                                    item={{
                                      url: file,
                                    }}
                                    height={212}
                                  />
                                ) : isImageFile(file) ? (
                                  <div className="max-w-[240px]">
                                    <ModalImage
                                      small={file}
                                      large={file}
                                      alt={`Attached File ${index}`}
                                    />
                                  </div>
                                ) : (
                                  <a
                                    href={file}
                                    className="text-white"
                                    download
                                  >
                                    {file
                                      ? file.split("?")[0].split("/").pop() ||
                                        null
                                      : null}
                                  </a>
                                )}
                              </li>
                            ))}
                          </ul>
                          <div
                            className="opacity-0 invisible group-hover:opacity-100 group-hover:!visible relative flex justify-center items-center border-0 w-6 h-6 ml-2 hover:bg-white hover:bg-opacity-50 rounded-full transition-all duration-150"
                            onClick={() => {
                              axiosClient
                                .delete(`/api/chat/message/${message.id}`)
                                .then((res) => {
                                  setMessageList(
                                    messageList.filter(
                                      (item) => item.id !== message.id
                                    )
                                  );
                                })
                                .catch((error) => console.error(error));
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{ fontSize: 12, color: "black" }}
                            />
                          </div>
                        </div>
                      )}
                  </Grid>
                  {message.text && message.text !== "" ? (
                    <Grid className="send-msg" container>
                      <Grid item>
                        <div className="flex items-center group">
                          <ListItemText
                            align="right"
                            primary={message.text}
                          ></ListItemText>
                          <div
                            className="opacity-0 invisible group-hover:opacity-100 group-hover:!visible relative flex justify-center items-center border-0 w-6 h-6 ml-2 hover:bg-white hover:bg-opacity-50 rounded-full transition-all duration-150"
                            onClick={() => {
                              axiosClient
                                .delete(`/api/chat/message/${message.id}`)
                                .then((res) => {
                                  setMessageList(
                                    messageList.filter(
                                      (item) => item.id !== message.id
                                    )
                                  );
                                })
                                .catch((error) => console.error(error));
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{ fontSize: 12, color: "white" }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <ListItemText
                          align="right"
                          secondary={
                            new Date(message.createdAt).getFullYear() ===
                            new Date().getFullYear()
                              ? new Date(message.createdAt).getDate() ===
                                new Date().getDate()
                                ? new Date(
                                    message.createdAt
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                : `${new Date(
                                    message.createdAt
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })} ${new Date(
                                    message.createdAt
                                  ).toLocaleDateString([], {
                                    month: "short",
                                    day: "numeric",
                                  })}`
                              : `${new Date(
                                  message.createdAt
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })} ${new Date(
                                  message.createdAt
                                ).toLocaleDateString([], {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                })}`
                          }
                        ></ListItemText>
                      </Grid>
                    </Grid>
                  ) : (
                    <p className="w-full text-sm text-right text-[#00000099]">
                      {new Date(message.createdAt).getFullYear() ===
                      new Date().getFullYear()
                        ? new Date(message.createdAt).getDate() ===
                          new Date().getDate()
                          ? new Date(message.createdAt).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : `${new Date(message.createdAt).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )} ${new Date(message.createdAt).toLocaleDateString(
                              [],
                              {
                                month: "short",
                                day: "numeric",
                              }
                            )}`
                        : `${new Date(message.createdAt).toLocaleTimeString(
                            [],
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )} ${new Date(message.createdAt).toLocaleDateString(
                            [],
                            {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }
                          )}`}
                    </p>
                  )}
                </>
              ) : (
                <>
                  <Grid className="receive-msg" container>
                    {message.attachedFiles &&
                      message.attachedFiles.length > 0 && (
                        <ul className="list-none px-3 rounded-xl mt-2 bg-[#F9F9F9]">
                          {message.attachedFiles.map((file, index) => (
                            <li
                              key={`message-${message.id}-attached-${index}`}
                              className="my-3"
                            >
                              {file.includes("pdf") ? (
                                <PdfViewer
                                  item={{
                                    url: file,
                                  }}
                                  height={212}
                                />
                              ) : isImageFile(file) ? (
                                <div className="max-w-[240px]">
                                  <ModalImage
                                    small={file}
                                    large={file}
                                    alt={`Attached File ${index}`}
                                  />
                                </div>
                              ) : (
                                <a href={file} download>
                                  {file
                                    ? file.split("?")[0].split("/").pop() ||
                                      null
                                    : null}
                                </a>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                  </Grid>
                  {message.text && message.text !== "" ? (
                    <Grid className="receive-msg" container>
                      <Grid item>
                        <ListItemText
                          align="left"
                          primary={message.text}
                        ></ListItemText>
                      </Grid>
                      <Grid item>
                        <ListItemText
                          align="left"
                          secondary={
                            new Date(message.createdAt).getFullYear() ===
                            new Date().getFullYear()
                              ? new Date(message.createdAt).getDate() ===
                                new Date().getDate()
                                ? new Date(
                                    message.createdAt
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                : `${new Date(
                                    message.createdAt
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })} ${new Date(
                                    message.createdAt
                                  ).toLocaleDateString([], {
                                    month: "short",
                                    day: "numeric",
                                  })}`
                              : `${new Date(
                                  message.createdAt
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })} ${new Date(
                                  message.createdAt
                                ).toLocaleDateString([], {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                })}`
                          }
                        ></ListItemText>
                      </Grid>
                    </Grid>
                  ) : (
                    <p className="w-full text-sm text-left text-[#00000099]">
                      {new Date(message.createdAt).getFullYear() ===
                      new Date().getFullYear()
                        ? new Date(message.createdAt).getDate() ===
                          new Date().getDate()
                          ? new Date(message.createdAt).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : `${new Date(message.createdAt).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )} ${new Date(message.createdAt).toLocaleDateString(
                              [],
                              {
                                month: "short",
                                day: "numeric",
                              }
                            )}`
                        : `${new Date(message.createdAt).toLocaleTimeString(
                            [],
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )} ${new Date(message.createdAt).toLocaleDateString(
                            [],
                            {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }
                          )}`}
                    </p>
                  )}
                </>
              )}
            </ListItem>
          ))}
        </>
      ) : (
        <ListItem>
          <Grid container>
            <Grid item>
              <ListItemText
                align="center"
                primary="No Conversation yet."
              ></ListItemText>
            </Grid>
          </Grid>
        </ListItem>
      )}
    </List>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(AQChatBody);
