import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Box, Button, Chip, Fade, Modal } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import aws from "aws-sdk";
import GoogleMapReact from "google-map-react";
import HomeIcon from "@material-ui/icons/Home";
import api from "../../api";
import { usStates } from "util/usStates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faClose,
  faLocationDot,
  faPen,
  faStar,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import axiosClient from "../../api";
import Review from "./component/review";
import { toastify, toastType } from "util/toastify";
import { toast } from "react-toastify";
import { logoutUser } from "actions/authAction";
import store from "store/store";

import { Document, Page } from "react-pdf";
import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import PdfViewer from "view/component/pdfViewer";

const defaultProps = {
  center: {
    lat: 37.409375796794684,
    lng: -122.08710396476175,
  },
  zoom: 10,
};

const style = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  textAlign: "center",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  paddingTop: "27px",
  paddingLeft: "53px",
  paddingRight: "53px",
  paddingBottom: "20px",
  borderRadius: "20px",
};

const Marker = () => {
  return <HomeIcon />;
};

export default function MyProfile() {
  const [user, setUser] = useState(null);

  const [activeServiceId, setActiveServiceId] = useState(0);
  const [activeService, setActiveService] = useState(null);

  const [portfolioList, setPortfolioList] = useState([]);
  const [certificationList, setCertificationList] = useState([]);

  const [lightBoxImg, setLightBoxImg] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);

  const [open, setOpen] = useState(false);

  const s3 = useMemo(() => new aws.S3(), []);

  const history = useHistory();

  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const getImageLink = useCallback(
    async (key) => {
      let params = { Bucket: "tradetribestore", Key: key };
      return await s3.getSignedUrlPromise("getObject", params);
    },
    [s3]
  );

  const getStateShortCode = (name) => {
    let val = usStates.find((state) => state.name === name.toUpperCase());
    return val ? val.abbreviation : name;
  };

  const deleteMyAccount = () => {
    axiosClient
      .delete("/api/users/delete-me")
      .then((res) => {
        store.dispatch(logoutUser());
        toast.success("Your account has been successfully deleted.", {
          position: "top-center",
          containerId: "main",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        history.push("/");
        setOpen(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to delete your account", {
          position: "top-center",
          containerId: "main",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setOpen(false);
      });
  };

  const handleClose = () => setOpen(false);
  const handleLightboxClose = () => {
    setLightBoxImg(null);
    setZoomLevel(1);
  };
  const handleZoomIn = () => setZoomLevel((prev) => prev + 0.1);
  const handleZoomOut = () => setZoomLevel((prev) => Math.max(prev - 0.1, 0.1));

  useEffect(() => {
    api
      .get("/api/me")
      .then(async (res) => {
        let tmp = { ...res.data.data.user };
        if (tmp && tmp.media) {
          tmp.media.url = await getImageLink(tmp.media.url);
        }
        setUser({ ...tmp, totalStars: res.data.data.totalStars });
      })
      .catch((err) => {
        console.error(err);
        toastify("Internal server error(s)", toastType.error);
      });
  }, [getImageLink]);

  useEffect(() => {
    if (user && user.services && user.services.length > 0) {
      axiosClient
        .get(`/api/service/${user.services[activeServiceId].UserService.id}`)
        .then(async (res) => {
          setActiveService(res.data.data.data);
          if (res.data.data.data.certification) {
            let certifications = res.data.data.data.certification;
            let urls = [];
            for (let i = 0; i < certifications.length; i++) {
              if (certifications[i].media) {
                let params = {
                  Bucket: "tradetribestore",
                  Key: certifications[i].media.url,
                };
                let url = await s3.getSignedUrlPromise("getObject", params);

                urls.push({ url, title: certifications[i].desc });
              }
            }
            setCertificationList(urls);
          } else {
            setCertificationList([]);
          }
          if (res.data.data.data.portfolio) {
            let portfolio = res.data.data.data.portfolio;
            let urls = [];
            for (let i = 0; i < portfolio.length; i++) {
              if (portfolio[i].media) {
                let params = {
                  Bucket: "tradetribestore",
                  Key: portfolio[i].media.url,
                };
                let url = await s3.getSignedUrlPromise("getObject", params);
                urls.push({ url, title: portfolio[i].desc });
              }
            }
            setPortfolioList(urls);
          } else {
            setPortfolioList([]);
          }
        })
        .catch((err) => {
          console.error(err);
          toastify("Internal server error(s)", toastType.error);
        });
    }
  }, [activeServiceId, s3, user]);

  return (
    <>
      <main className="flex-1 profile px-[20px] max-md:px-[10px]">
        <div className="container items-center justify-between p-[45px] bg-[#F9F9F9] rounded-[20px] mb-[30px] max-md:block">
          {user && (
            <div className="flex items-center max-md:mb-[20px]">
              <Stack direction="row" spacing={2} className="justify-center">
                <Link to={`/user-profile/${user.id}`}>
                  <Avatar
                    alt="Review User Avatar"
                    src={user.media ? user.media.url : ""}
                    className="min-w-[90px] min-h-[90px]"
                  />
                </Link>
              </Stack>
              <div className="ml-[23px]">
                <h2 className="text-left text-[25px]">
                  {user.firstName + " " + user.lastName}
                </h2>
                <div className="flex">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{ fontSize: 14, color: "#1CC400" }}
                  />
                  <p className="text-[15px] ml-[3px]">
                    {user.city ? user.city : "Los Angeles"},{" "}
                    {getStateShortCode(user.state ? user.state : "California")}
                  </p>
                </div>
                <div className="flex">
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ fontSize: 14, color: "#1CC400" }}
                  />
                  <p className="text-[15px] ml-[3px]">
                    Member since: {new Date(user.createdAt).getFullYear()}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="flex">
            <div className="flex items-center">
              <button
                onClick={() => setOpen(true)}
                className="text-white py-3 px-8 mr-2 !bg-rose-700 leading-5 !border-0 capitalize rounded-full transition-all duration-300 hover:!bg-rose-900 hover:!border-main"
              >
                Delete Account
              </button>
            </div>
            <div className="flex items-center">
              <Link
                to="/edit-profile"
                className="!text-white py-3 px-8 !bg-second !border-second capitalize rounded-full transition-all duration-300 hover:!bg-main hover:!border-main"
              >
                Edit Profile
              </Link>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row flex-1">
            {user && (
              <div className="grid grid-cols-12 w-full gap-8">
                <div className="side col-span-12 md:col-span-4 lg:col-span-3 mb-5 md:min-w-[250px] max-md:mb-6 max-sm:gap-0">
                  <div className="p-8 bg-[#f9f9f9] rounded-[20px] mb-3">
                    <h3 className="text-left">Your Services</h3>
                    <div className={user.services.length > 0 ? "mt-4" : "mt-2"}>
                      {user.services.length > 0 ? (
                        user.services.map((service, index) => (
                          <button
                            key={`my-service-${index}`}
                            type="button"
                            onClick={() => setActiveServiceId(index)}
                            className={
                              "flex justify-between items-center w-full py-[8px] pl-4 pr-3 mb-[12px] relative rounded-full transition-color duration-300 " +
                              (index === activeServiceId
                                ? "border-second bg-second hover:bg-main hover:border-main"
                                : "border-[#f0f0f0] bg-[#f0f0f0] hover:bg-[#ccc] hover:border-[#ccc]")
                            }
                          >
                            <span
                              title={service.title}
                              className={
                                "whitespace-nowrap overflow-ellipsis overflow-hidden " +
                                (index === activeServiceId ? "text-white" : "")
                              }
                            >
                              {service.title} ({service.type?.toUpperCase()})
                            </span>
                            <Link
                              title="Edit Service"
                              to={`/edit-service?serviceId=${service.UserService.id}`}
                              className={
                                index === activeServiceId ? "text-white" : ""
                              }
                            >
                              <FontAwesomeIcon
                                icon={faPen}
                                style={{
                                  fontSize: 18,
                                  color:
                                    index === activeServiceId
                                      ? "white"
                                      : "#292d32",
                                }}
                              />
                            </Link>
                          </button>
                        ))
                      ) : (
                        <p className="mb-3">No service is added here.</p>
                      )}
                      {user.services.length < 3 && (
                        <button
                          type="button"
                          title="Add New Service"
                          className="group w-full py-1 border-[#f0f0f0] bg-[#f0f0f0] rounded-full transition-color duration-300 hover:bg-[#ccc] hover:border-[#ccc]"
                        >
                          <FontAwesomeIcon
                            icon={faCirclePlus}
                            style={{ fontSize: 18 }}
                            className="text-main mr-[10px]"
                          />
                          <Link
                            to="/add-service"
                            className="group-hover:text-main"
                          >
                            Add New Service
                          </Link>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="relative pt-[28px] px-[19px] pb-[21px] bg-[#f9f9f9] rounded-[20px]">
                    <Link
                      to={`/edit-location?serviceId=${user.id}`}
                      className="text-white"
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{ fontSize: 18 }}
                        className="absolute top-[1.8rem] right-4 text-[#292D32]"
                      />
                    </Link>
                    <h3 className="text-left">Your Location</h3>
                    {user.state && user.city && (
                      <p className="text-left my-[12px] text-[15px]">
                        {user.city}, {getStateShortCode(user.state)}
                      </p>
                    )}
                    {user.lat && user.lng ? (
                      <div className="h-40">
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                            language: "en",
                          }}
                          center={{
                            lat: user.lat,
                            lng: user.lng,
                          }}
                          defaultZoom={defaultProps.zoom}
                          draggable={false}
                        >
                          <Marker lat={user.lat} lng={user.lng} />
                        </GoogleMapReact>
                      </div>
                    ) : (
                      <p className="mt-4">
                        You haven't added your address yet. If you want, please
                        press pencil icon above.
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-12 md:col-span-8 lg:col-span-9 pb-16 md:ml-10 md:pl-4">
                  {activeService ? (
                    <>
                      <div className="flex items-center justify-between mb-[22px]">
                        <div>
                          <h2 className="flex items-center text-left mb-2">
                            {activeService.service.title}
                            {activeService.service.type && (
                              <Chip
                                label={activeService.service.type.toUpperCase()}
                                className="ml-2 text-sm"
                              />
                            )}
                          </h2>
                          <div className="flex items-center gap-2">
                            <FontAwesomeIcon
                              icon={faStar}
                              style={{ fontSize: 18, color: "#1CC300" }}
                            />
                            <p>
                              {user.receivedReviews.length > 0
                                ? (
                                    user.totalStars /
                                    user.receivedReviews.length
                                  ).toFixed(1)
                                : 0}{" "}
                              ({user.receivedReviews.length} reviews)
                            </p>
                          </div>
                        </div>
                        {activeService && (
                          <div className="flex items-center gap-2">
                            <img
                              src="/images/icon.png"
                              alt="icon"
                              width="26"
                              height="23"
                            />
                            <p className="text-[20px] ml-[3px]">
                              {activeService.rate} per task
                            </p>
                          </div>
                        )}
                      </div>
                      <p className="text-left pr-[20px] mb-[10px]">
                        {activeService.experience}
                      </p>
                      <p className="text-left pr-[20px] pb-[40px] border-b border-x-0 border-t-0 border-solid border-[#e9e9e9]">
                        {user.services[activeServiceId].UserService.detail}
                      </p>
                      {activeService.requireWorkSamples && (
                        <>
                          <h2 className="mt-[40px] mb-[23px]">Work Samples</h2>
                          <Swiper
                            className="border-b border-x-0 border-t-0 border-solid border-[#e9e9e9]"
                            modules={[Navigation]}
                            spaceBetween={15}
                            slidesPerView={4}
                            breakpoints={{
                              360: {
                                slidesPerView: 1,
                              },
                              640: {
                                slidesPerView: 2,
                              },
                              1024: {
                                slidesPerView: 4,
                              },
                            }}
                          >
                            {portfolioList.length > 0 ? (
                              <>
                                {portfolioList.map((item, index) => (
                                  <SwiperSlide key={`portfolio-${index}`}>
                                    {item.url.includes(".pdf") ? (
                                      <PdfViewer item={item} height={212} />
                                    ) : (
                                      <img
                                        src={item.url}
                                        alt="work-1"
                                        width={212}
                                        height={212}
                                        className="w-full object-cover cursor-pointer"
                                        onClick={() => setLightBoxImg(item.url)}
                                      />
                                    )}
                                    <p className="pb-[40px] mt-[18px] text-[15px] font-medium">
                                      {item.title}
                                    </p>
                                  </SwiperSlide>
                                ))}
                              </>
                            ) : (
                              <SwiperSlide>
                                <img
                                  src="/images/no-image.jpg"
                                  alt="work-2"
                                  className="w-full object-cover"
                                />
                                <p className="pb-[40px] mt-[18px] text-[15px] font-medium">
                                  No Work Sample Added
                                </p>
                              </SwiperSlide>
                            )}
                          </Swiper>
                        </>
                      )}
                      {activeService.requireCertifications && (
                        <>
                          <h2 className="mt-[40px] mb-[23px]">
                            Professional Certifications
                          </h2>
                          <Swiper
                            className="border-b border-x-0 border-t-0 border-solid border-[#e9e9e9]"
                            modules={[Navigation]}
                            spaceBetween={15}
                            slidesPerView={4}
                            breakpoints={{
                              360: {
                                slidesPerView: 1,
                              },
                              640: {
                                slidesPerView: 2,
                              },
                              1024: {
                                slidesPerView: 4,
                              },
                            }}
                          >
                            {certificationList.length > 0 ? (
                              <>
                                {certificationList.map((item, index) => (
                                  <SwiperSlide key={`certification-${index}`}>
                                    {item.url.includes(".pdf") ? (
                                      <PdfViewer item={item} height={212} />
                                    ) : (
                                      <img
                                        src={item.url}
                                        alt="work-1"
                                        width={212}
                                        height={212}
                                        className="w-full object-cover cursor-pointer"
                                        onClick={() => setLightBoxImg(item.url)}
                                      />
                                    )}
                                    <p className="pb-[40px] mt-[18px] text-[15px] font-medium">
                                      {item.title}
                                    </p>
                                  </SwiperSlide>
                                ))}
                              </>
                            ) : (
                              <>
                                <SwiperSlide>
                                  <img
                                    src="/images/no-image.jpg"
                                    alt="work-2"
                                    className="w-full object-cover"
                                  />
                                  <p className="pb-[40px] mt-[18px] text-[15px] font-medium">
                                    No Certification Added
                                  </p>
                                </SwiperSlide>
                              </>
                            )}
                          </Swiper>
                        </>
                      )}
                      <div className="flex my-[40px]">
                        <h2>Reviews</h2>
                        <div className="flex ml-[15px] items-center">
                          <FontAwesomeIcon
                            icon={faStar}
                            style={{ fontSize: 18, color: "#1CC300" }}
                          />
                          <p className="mb-[-3px]">
                            {user.receivedReviews.length > 0
                              ? (
                                  user.totalStars / user.receivedReviews.length
                                ).toFixed(1)
                              : 0}{" "}
                            ({user.receivedReviews.length} reviews)
                          </p>
                        </div>
                      </div>
                      {user.receivedReviews.length > 0 ? (
                        user.receivedReviews.map((review, index) => (
                          <Review key={`review-${index}`} data={review} />
                        ))
                      ) : (
                        <div className="review pb-[24px] mb-[33px] border-b border-x-0 border-t-0 border-solid border-[#e9e9e9]">
                          <div className="flex justify-between items-center">
                            <div className="user-info flex items-center">
                              <h3>No Reviews</h3>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="w-full p-8 bg-[#f9f9f9] rounded-[20px]">
                      <p>No service is selected.</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {user && (
          <div className="pt-[85px] pb-[71px] px-[195px] bg-[#0b4e0008] max-lg:px-[20px]">
            <h2 className="mb-[51px]">
              About {`${user.firstName} ${user.lastName}`}
            </h2>
            <div className="flex items-center">
              <Stack
                direction="row"
                spacing={2}
                className="justify-center mr-[18px]"
              >
                <Avatar
                  alt="About Joshua"
                  src={user.media ? user.media.url : ""}
                  className="min-w-[90px] min-h-[90px]"
                />
              </Stack>
              <div className="desc relative">
                <p className="text-left text-[20px]">
                  <div className="inline-block relative">
                    <img
                      src="/images/quote-1.png"
                      alt="quote-1"
                      width="36"
                      height="26"
                      className="absolute -top-6 -left-9"
                    />
                  </div>
                  <span>{user.userBio}</span>
                  <div className="inline-block relative">
                    <img
                      src="/images/quote-2.png"
                      alt="quote-2"
                      width="36"
                      height="26"
                      className="absolute -bottom-3 -right-9"
                    />
                  </div>
                </p>
              </div>
            </div>
          </div>
        )}
      </main>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="max-md:max-w-[90vw]">
            <h3 className="pb-4">Would you like to delete your account?</h3>
            <div className="flex justify-around">
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button onClick={deleteMyAccount}>OK</Button>
            </div>
          </Box>
        </Fade>
      </Modal>
      {lightBoxImg && (
        <div className="fixed flex flex-col justify-between items-center top-0 left-0 w-screen h-screen z-[100] bg-black bg-opacity-50">
          <div className="h-14 px-6 w-screen bg-stone-950 flex justify-end p-2 z-[101]">
            <button
              onClick={handleLightboxClose}
              className="flex justify-center items-center w-10 h-10 bg-transparent border-none cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faClose}
                style={{ fontSize: 20, color: "white" }}
              />
            </button>
          </div>
          <div
            className="flex flex-1 w-screen justify-center items-center"
            onClick={handleLightboxClose}
          >
            <img
              src={lightBoxImg}
              alt="LightBox"
              style={{ transform: `scale(${zoomLevel})` }}
              className=""
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <div className="fixed flex items-center space-x-4 bottom-6">
            <Button
              onClick={handleZoomOut}
              color="primary"
              variant="contained"
              sx={{ color: "white" }}
            >
              Zoom Out
            </Button>
            <Button
              onClick={handleZoomIn}
              color="primary"
              variant="contained"
              sx={{ color: "white" }}
            >
              Zoom In
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
