import { Avatar, Badge, Box, Stack, styled, Tab, Tabs } from "@mui/material";
import axiosClient from "api";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import aws from "aws-sdk";
import { toastify, toastType } from "util/toastify";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useMemo } from "react";
import { connect } from "react-redux";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanels(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanels-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanels.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const status = [
  "ACCEPTED",
  "REQUESTED",
  "REVIEWED",
  "REJECTED",
  "CANCELTASK",
  "CANCELACCEPTED",
  "CANCELREJECTED",
  "COMPLETETASK",
  "COMPLETEACCEPTED",
  "COMPLETEREJECTED",
];

const ProvidingServices = ({ socket, auth }) => {
  const [tasks, setTasks] = useState([]);
  const [value, setValue] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const history = useHistory();
  const s3 = useMemo(() => new aws.S3(), []);

  const location = useLocation();

  useEffect(() => {
    let isMounted = true;

    axiosClient
      .post("/api/task/get-providing-services", {
        status: [status[0], status[4], status[6], status[7], status[9]],
      })
      .then(async (res) => {
        if (!isMounted) return;

        let tasks = res.data.data.tasks;
        for (let i = 0; i < tasks.length; i++) {
          if (tasks[i].receiver && tasks[i].receiver.media) {
            let params = {
              Bucket: "tradetribestore",
              Key: tasks[i].receiver.media.url,
            };
            tasks[i].receiver.media.url = await s3.getSignedUrlPromise(
              "getObject",
              params
            );
          }
        }
        if (isMounted) {
          setTasks(tasks);
        }
      })
      .catch((err) => {
        if (isMounted) console.error(err);
      });

    axiosClient
      .post("/api/task/get-providing-services", {
        status: [status[1]],
      })
      .then(async (res) => {
        if (!isMounted) return;

        let tasks = res.data.data.tasks;
        let pendingTasksCount = tasks.filter(
          (task) => task.status === "REQUESTED"
        ).length;
        setPendingCount(pendingTasksCount);
      })
      .catch((err) => {
        if (isMounted) console.error(err);
      });

    return () => {
      isMounted = false;
    };
  }, [s3]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const param = queryParams.get("type");
    switch (param) {
      case "SERVICEREQUESTED":
        setValue(1);
        break;
      case "SERVICEREJECTED":
      case "SERVICECANCELLED":
        setValue(3);
        break;
      case "SERVICECOMPLETED":
        setValue(2);
        break;
      default:
        setValue(0);
        break;
    }
  }, [location]);

  useEffect(() => {
    let statusList = [];
    switch (value) {
      case 0:
        statusList = [status[0], status[4], status[6], status[7], status[9]];
        break;
      case 1:
        statusList = [status[1]];
        break;
      case 2:
        statusList = [status[2], status[8]];
        break;
      case 3:
        statusList = [status[3], status[5]];
        break;
      default:
        statusList = [status[0], status[4], status[6], status[7], status[9]];
        break;
    }
    axiosClient
      .post("/api/task/get-providing-services", {
        status: statusList,
      })
      .then(async (res) => {
        let tmp = [...res.data.data.tasks];
        for (let i = 0; i < tmp.length; i++) {
          if (tmp[i].receiver && tmp[i].receiver.media) {
            let params = {
              Bucket: "tradetribestore",
              Key: tmp[i].receiver.media.url,
            };
            tmp[i].receiver.media.url = await s3.getSignedUrlPromise(
              "getObject",
              params
            );
          }
        }
        setTasks(tmp);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [s3, value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTasks([]);
  };

  const acceptHandler = (taskId) => {
    let tmp = tasks.filter((item) => item.id !== taskId);
    let tmp1 = tasks.find((item) => item.id === taskId);
    setTasks(tmp);
    axiosClient
      .post("/api/task/accept-task", {
        taskId,
      })
      .then((_res) => {
        toastify("Service Request successfully accepted.", toastType.success);
        history.push("/task?taskId=" + taskId);
        socket.emit("SERVICEACCEPTED", {
          senderId: tmp1.providerId,
          receiverId: tmp1.receiverId,
          taskId,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const rejectHandler = (taskId) => {
    let tmp = tasks.filter((item) => item.id !== taskId);
    let tmp1 = tasks.find((item) => item.id === taskId);
    axiosClient
      .post("/api/task/reject-task", {
        taskId,
      })
      .then((res) => {
        setTasks(tmp);
        toastify("Service Request successfully rejected.", toastType.success);
        console.log("SERVICEREJECTED", {
          senderId: auth.user.userId,
          receiverId: [tmp1.receiverId, tmp1.providerId].filter(
            (item) => parseInt(item) !== auth.user.userId
          )[0],
          taskId,
          amount: tmp1.rate,
        });
        socket.emit("SERVICEREJECTED", {
          senderId: auth.user.userId,
          receiverId: [tmp1.receiverId, tmp1.providerId].filter(
            (item) => parseInt(item) !== auth.user.userId
          )[0],
          taskId,
          amount: tmp1.rate,
        });
      })
      .catch((err) => {
        console.error(err);
        toastify("Internal Server Error(s)", toastType.error);
      });
  };

  return (
    <>
      <div className="flex justify-between items-center max-xl:block">
        <h2 className="max-xl:mb-4 sm:text-center">Service I'm Giving</h2>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="main"
          aria-label="my tab"
          className="text-[#000]"
        >
          <Tab
            className="max-sm:!mb-4"
            label="Accepted task"
            {...a11yProps(0)}
          />
          <Tab
            className="max-sm:!mb-4"
            label={
              <div className="flex items-center gap-1">
                <span>Pending Requests</span>
                {pendingCount > 0 && (
                  <div className="flex justify-center items-center w-5 h-5 min-w-[20px] bg-main rounded-full text-white">
                    {pendingCount}
                  </div>
                )}
              </div>
            }
            {...a11yProps(1)}
          />
          <Tab className="max-sm:!mb-4" label="Completed" {...a11yProps(2)} />
          <Tab className="max-sm:!mb-4" label="Rejected" {...a11yProps(3)} />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <Box>
          {tasks.filter((item) => !!item.receiver).length > 0 ? (
            tasks.map((item, index) => (
              <>
                {item.receiver && (
                  <div
                    key={`accepted-${index}`}
                    className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1"
                  >
                    <div className="flex justify-between items-center">
                      <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                        {item.requirement}
                      </p>
                      <Link
                        to={`/user-profile/${item.receiver.id}`}
                        className="flex items-center"
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          className="justify-center mt-6 mb-3"
                        >
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            variant="dot"
                            color={
                              (new Date() - new Date(item.receiver.updatedAt)) /
                                (1000 * 60) <
                              5
                                ? "primary"
                                : "warning"
                            }
                          >
                            <Avatar
                              alt={`${item.receiver.firstName} ${item.receiver.lastName}`}
                              src={
                                item.receiver.media
                                  ? item.receiver.media.url
                                  : ""
                              }
                            />
                          </StyledBadge>
                        </Stack>
                        <p className="ml-[5px]">{`${item.receiver.firstName} ${item.receiver.lastName}`}</p>
                      </Link>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="point">
                        <h3 className="text-[12px] font-medium">Points</h3>
                        <p className="text-[15px] text-[#7c7c7c] font-medium">
                          {item.rate} pts
                        </p>
                      </div>
                      <div className="status">
                        <h3 className="text-[12px] font-medium">Status</h3>
                        <p className="text-[15px] text-main font-medium">
                          In Progress
                        </p>
                      </div>
                      <Link
                        to={`/task?taskId=${item.id}`}
                        className="text-[15px] text-main font-medium"
                      >
                        View
                      </Link>
                    </div>
                  </div>
                )}
              </>
            ))
          ) : (
            <div className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1">
              <div className="flex justify-between items-center">
                <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                  No Service In Progress
                </p>
              </div>
            </div>
          )}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box>
          {tasks.filter((item) => !!item.receiver).length > 0 ? (
            tasks.map((item, index) => (
              <>
                {item.receiver && (
                  <div
                    key={`pending-${index}`}
                    className="flex flex-col border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px]"
                  >
                    <div className="grid grid-cols-2 gap-20 items-center max-xl:grid-cols-1">
                      <div className="flex justify-between items-center">
                        <Link
                          to={`/user-profile/${item.receiver.id}`}
                          className="flex items-center"
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            className="justify-center mt-6 mb-3"
                          >
                            <StyledBadge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              variant="dot"
                              color={
                                (new Date() -
                                  new Date(item.receiver.updatedAt)) /
                                  (1000 * 60) <
                                5
                                  ? "primary"
                                  : "warning"
                              }
                            >
                              <Avatar
                                alt={`${item.receiver.firstName} ${item.receiver.lastName}`}
                                src={
                                  item.receiver.media
                                    ? item.receiver.media.url
                                    : ""
                                }
                              />
                            </StyledBadge>
                          </Stack>
                          <p className="ml-[5px]">{`${item.receiver.firstName} ${item.receiver.lastName}`}</p>
                        </Link>
                        <div className="point">
                          <h3 className="text-[12px] font-medium">Points</h3>
                          <p className="text-[15px] text-[#7c7c7c] font-medium">
                            {item.rate} pts
                          </p>
                        </div>
                        <div className="status">
                          <h3 className="text-[12px] font-medium">Status</h3>
                          <p className="text-[15px] text-main font-medium">
                            Pending
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                          {item.requirement}
                        </p>
                        <div className="flex flex-col">
                          <button
                            onClick={() => acceptHandler(item.id)}
                            className="text-[16px] py-2 px-4 my-1 text-white bg-[#0B4E00] hover:bg-main font-medium border-0 rounded-full transition-all duration-300"
                          >
                            Accept
                          </button>
                          <button
                            onClick={() => rejectHandler(item.id)}
                            className="text-[16px] py-2 px-4 my-1 text-white bg-[#ac1018] hover:bg-[#FF000E] font-medium border-0 rounded-full transition-all duration-300"
                          >
                            Reject
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <p className="text-sm">
                        Points you're receiving for your service will
                        automatically be transferred to you from{" "}
                        {`${item.receiver.firstName} ${item.receiver.lastName}`}
                        's account upon mutual agreement that service has been
                        completed.
                      </p>
                    </div>
                  </div>
                )}
              </>
            ))
          ) : (
            <div className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1">
              <div className="flex justify-between items-center">
                <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                  No Requested Service
                </p>
              </div>
            </div>
          )}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box>
          {tasks.filter((item) => !!item.receiver).length > 0 ? (
            tasks.map((item, index) => (
              <>
                {item.receiver && (
                  <div
                    key={`completed-${index}`}
                    className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1"
                  >
                    <div className="flex justify-between items-center">
                      <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                        {item.requirement}
                      </p>
                      <Link
                        to={`/user-profile/${item.receiver.id}`}
                        className="flex items-center"
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          className="justify-center mt-6 mb-3"
                        >
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            variant="dot"
                            color={
                              (new Date() - new Date(item.receiver.updatedAt)) /
                                (1000 * 60) <
                              5
                                ? "primary"
                                : "warning"
                            }
                          >
                            <Avatar
                              alt={`${item.receiver.firstName} ${item.receiver.lastName}`}
                              src={
                                item.receiver.media
                                  ? item.receiver.media.url
                                  : ""
                              }
                            />
                          </StyledBadge>
                        </Stack>
                        <p className="ml-[5px]">{`${item.receiver.firstName} ${item.receiver.lastName}`}</p>
                      </Link>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="point">
                        <h3 className="text-[12px] font-medium">Points</h3>
                        <p className="text-[15px] text-[#7c7c7c] font-medium">
                          {item.rate} pts
                        </p>
                      </div>
                      <div className="status">
                        <h3 className="text-[12px] font-medium">Status</h3>
                        <p className="text-[15px] text-main font-medium">
                          Completed
                        </p>
                      </div>
                      <div className="time">
                        <h3 className="text-[12px] font-medium">
                          Completed At
                        </h3>
                        <p className="text-[15px] text-main font-medium">
                          {item.updatedAt
                            ? new Date(item.updatedAt).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                }
                              )
                            : "NaN"}
                        </p>
                      </div>
                      <Link
                        to={`/task?taskId=${item.id}`}
                        className="text-[15px] text-main font-medium"
                      >
                        View
                      </Link>
                    </div>
                  </div>
                )}
              </>
            ))
          ) : (
            <div className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1">
              <div className="flex justify-between items-center">
                <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                  No Completed Service
                </p>
              </div>
            </div>
          )}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Box>
          {tasks.filter((item) => !!item.receiver).length > 0 ? (
            tasks.map((item, index) => (
              <>
                {item.receiver && (
                  <div
                    key={`rejected-${index}`}
                    className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1"
                  >
                    <div className="flex justify-between items-center">
                      <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                        {item.requirement}
                      </p>
                      <Link
                        to={`/user-profile/${item.receiver.id}`}
                        className="flex items-center"
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          className="justify-center mt-6 mb-3"
                        >
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            variant="dot"
                            color={
                              (new Date() - new Date(item.receiver.updatedAt)) /
                                (1000 * 60) <
                              5
                                ? "primary"
                                : "warning"
                            }
                          >
                            <Avatar
                              alt={`${item.receiver.firstName} ${item.receiver.lastName}`}
                              src={
                                item.receiver.media
                                  ? item.receiver.media.url
                                  : ""
                              }
                            />
                          </StyledBadge>
                        </Stack>
                        <p className="ml-[5px]">{`${item.receiver.firstName} ${item.receiver.lastName}`}</p>
                      </Link>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="point">
                        <h3 className="text-[12px] font-medium">Points</h3>
                        <p className="text-[15px] text-[#7c7c7c] font-medium">
                          {item.rate} pts
                        </p>
                      </div>
                      <div className="status">
                        <h3 className="text-[12px] font-medium">Status</h3>
                        <p className="text-[15px] text-main font-medium">
                          Rejected
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))
          ) : (
            <div className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1">
              <div className="flex justify-between items-center">
                <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                  No Rejected Service
                </p>
              </div>
            </div>
          )}
        </Box>
      </TabPanel>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProvidingServices);
