import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { slugify } from "../../util/slugify";
import { myBucket } from "../../util/s3";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { getFileNameAndExtension } from "util/getFileNameAndExtension";
import CertificationCardOnSignUp from "./certificationCardOnSignUp";
import { useAtom } from "jotai";
import { serviceRegistration } from "store/jotai";
// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

export default function CertificationImageUploadOnSignUp({ items, setter }) {
  const [newFile, setNewFile] = useState([]);
  const [newTitle, setNewTitle] = useState("");
  const [loaded, setLoaded] = useState(false);

  const [regTemp, setRegTemp] = useAtom(serviceRegistration);
  const [, setRegTempStatus] = useState(null);
  useEffect(() => {
    setRegTempStatus(regTemp);
  }, [regTemp]);

  useEffect(() => {
    if (newFile.length < 1) setLoaded(false);
  }, [newFile]);

  const addNewItem = async () => {
    if (newFile.length < 1) {
      return toast.error("Please select a file.", {
        position: "top-center",
        containerId: "main",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (!newTitle || newTitle === "") {
      return toast.error("Description is required for certification.", {
        position: "top-center",
        containerId: "main",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const temp = [
      ...items,
      {
        name: newFile[0].serverId,
        desc: newTitle,
        media: {
          url: newFile[0].serverId,
        },
      },
    ];

    setter(temp);
    setRegTemp({
      ...regTemp,
      certifications: temp,
    });

    // Show success toast after adding the new item
    toast.success("Certification is added successfully!", {
      position: "top-center",
      containerId: "main",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    setNewFile([]);
    setNewTitle("");

    setLoaded(false);
  };

  const removeHandler = (name) => {
    const temp = items.filter((item) => item.name !== name);

    setter(temp);
    setRegTemp({
      ...regTemp,
      certifications: temp,
    });
  };

  const changeDescHandler = (name, desc) => {
    const temp = items.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          desc,
        };
      } else {
        return item;
      }
    });

    setter(temp);
    setRegTemp({
      ...regTemp,
      certifications: temp,
    });
  };

  return (
    <div className="upload__image-wrapper">
      <div className="flex justify-between items-center gap-4 mb-3">
        <h3>Service Certifications</h3>
      </div>
      <div className="App">
        <FilePond
          files={newFile}
          onupdatefiles={setNewFile}
          allowMultiple={false}
          maxFiles={1}
          // accepted-file-types={"application/pdf"}
          acceptedFileTypes={["application/pdf", "image/*"]}
          server={{
            process: function (
              fieldName,
              file,
              metadata,
              load,
              error,
              progress,
              abort
            ) {
              const fileName = getFileNameAndExtension(file.name);

              myBucket.upload(
                {
                  Bucket: "tradetribestore",
                  Key:
                    Date.now() +
                    "_" +
                    slugify(fileName.name) +
                    "." +
                    fileName.extension,
                  Body: file,
                  ContentType: file.type,
                  ACL: "public-read",
                },
                function (err, data) {
                  setLoaded(true);
                  if (err) {
                    error("Something went wrong");
                    return;
                  }

                  // pass file unique id back to filepond
                  load(data.Key);
                }
              );
            },
          }}
          name="newFile"
          labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
        />
        <div className="flex gap-2 mb-3">
          <input
            className="w-full h-[50px] pl-4 rounded-xl border border-solid border-[#e9e9e9] outline-none "
            type="text"
            placeholder="No Description"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
          <button
            type="button"
            onClick={addNewItem}
            disabled={newFile.length < 1 || !loaded}
            className={
              newFile.length < 1 || !loaded
                ? "block py-2 px-4 sm:px-8 text-white bg-gray-500 border-none outline-none rounded-xl transition-colors duration-300 hover:bg-[#2bef0a] focus:outline-none active:outline-none pointer-events-none opacity-80"
                : "block py-2 px-4 sm:px-8 text-white bg-main border-none outline-none rounded-xl transition-colors duration-300 hover:bg-[#2bef0a] focus:outline-none active:outline-none"
            }
          >
            Save
          </button>
        </div>
      </div>
      <div>
        {items.map((item, index) => (
          <div key={`new-certification-${index}`}>
            <CertificationCardOnSignUp
              item={item}
              removeHandler={removeHandler}
              changeDescHandler={changeDescHandler}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
