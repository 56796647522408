import React, { useEffect, useMemo, useState, useRef } from "react";
import aws from "aws-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import BasicCard from "../component/card";
import axiosClient from "api";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { toastify, toastType } from "util/toastify";
import * as animationData from "../../assets/loading.json";

import { GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import Lottie from "react-lottie";

const SingleService = (props) => {
  const [countFreelancers, setCountFreelancers] = useState(0);
  const [freelacnerList, setFreelancerList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchLocation, setSearchLocation] = useState("global");
  const [serviceType, setServiceType] = useState("both");
  const [loading, setLoading] = useState(false);
  const [location1, setLocation1] = useState(null);
  const [searchRadius, setSearchRadius] = useState(80);

  const s3 = useMemo(() => new aws.S3(), []);
  const search = useRef(null);

  useEffect(() => {
    const keyword = localStorage.getItem("searchKeyword");
    const searchLocation =
      localStorage.getItem("searchLocation") === ""
        ? "global"
        : localStorage.getItem("searchLocation");
    const serviceType =
      localStorage.getItem("serviceType") === ""
        ? "both"
        : localStorage.getItem("serviceType");
    setSearchKeyword(keyword);

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    let mounted = true;

    const success = (pos) => {
      const currentPos = pos.coords;

      axiosClient
        .post("/public/get-freelancer-list", {
          offset: 0,
          limit: 9,
          keyword: keyword,
          location: searchLocation,
          serviceType: serviceType,
          searchRadius: searchRadius,
          currentPos: {
            latitude: currentPos.latitude,
            longitude: currentPos.longitude,
          },
        })
        .then(async (res) => {
          if (mounted) {
            setCountFreelancers(res.data.data.freelancers.count);
            let tmp = [...res.data.data.freelancers.rows];
            for (let i = 0; i < tmp.length; i++) {
              if (tmp[i].media) {
                let params = {
                  Bucket: "tradetribestore",
                  Key: tmp[i].media.url,
                };
                tmp[i].media.url = await s3.getSignedUrlPromise(
                  "getObject",
                  params
                );
              }
            }
            setFreelancerList(tmp);
          }
        })
        .catch((err) => {
          if (mounted) {
            console.error(err);
            toastify("Internal Server Error(s)", toastType.error);
          }
        });
    };

    const error = (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    };

    navigator.geolocation.getCurrentPosition(success, error, options);

    return () => {
      mounted = false;
      localStorage.setItem("searchLocation", "");
      localStorage.setItem("serviceType", "");
    };
  }, [s3, searchRadius]);

  const handleSelect = async (selected) => {
    const results = await geocodeByAddress(selected.label);
    const latLng = await getLatLng(results[0]);
    setLocation1(latLng);
  };

  const searchHandler = (event) => {
    event.preventDefault();
    localStorage.setItem("searchKeyword", search.current.value);
    setSearchKeyword(search.current.value);

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const success = (pos) => {
      const currentPos = pos.coords;

      axiosClient
        .post("/public/get-freelancer-list", {
          offset: 0,
          limit: 9,
          keyword: search.current.value,
          location: searchLocation,
          serviceType: serviceType,
          currentPos: {
            latitude: currentPos.latitude,
            longitude: currentPos.longitude,
          },
          searchRadius: searchRadius,
        })
        .then(async (res) => {
          setCountFreelancers(res.data.data.freelancers.count);
          let tmp = [...res.data.data.freelancers.rows];
          for (let i = 0; i < tmp.length; i++) {
            if (tmp[i].media) {
              let params = {
                Bucket: "tradetribestore",
                Key: tmp[i].media.url,
              };
              tmp[i].media.url = await s3.getSignedUrlPromise(
                "getObject",
                params
              );
            }
          }
          setFreelancerList(tmp);
        })
        .catch((err) => {
          console.error(err);
          toastify("Internal Server Error(s)", toastType.error);
        });
    };

    const error = (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  const showMoreHandler = () => {
    const searchLocation =
      localStorage.getItem("searchLocation") === ""
        ? "global"
        : localStorage.getItem("searchLocation");
    const serviceType =
      localStorage.getItem("serviceType") === ""
        ? "both"
        : localStorage.getItem("serviceType");

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const success = (pos) => {
      const currentPos = pos.coords;
      if (freelacnerList.length < countFreelancers) {
        setLoading(true);
        axiosClient
          .post("/public/get-freelancer-list", {
            offset: freelacnerList.length,
            limit: 6,
            keyword: searchKeyword,
            location: searchLocation,
            serviceType: serviceType,
            currentPos: {
              latitude: currentPos.latitude,
              longitude: currentPos.longitude,
            },
          })
          .then(async (res) => {
            setCountFreelancers(res.data.data.freelancers.count);
            let tmp = [...res.data.data.freelancers.rows];
            for (let i = 0; i < tmp.length; i++) {
              if (tmp[i].media) {
                let params = {
                  Bucket: "tradetribestore",
                  Key: tmp[i].media.url,
                };
                tmp[i].media.url = await s3.getSignedUrlPromise(
                  "getObject",
                  params
                );
              }
            }
            setLoading(false);
            setFreelancerList([...freelacnerList, ...tmp]);
          })
          .catch((err) => {
            console.error(err);
            toastify("Internal Server Error(s)", toastType.error);
          });
      }
    };

    const error = (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  return (
    <main className="flex-1 single-service">
      <div className="container">
        <div className="w-full mt-10 grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-4 xl:col-span-3 flex flex-col justify-center">
            {searchKeyword === "" || !searchKeyword ? (
              <h2 className="mb-[13px]">No keyword Provided.</h2>
            ) : (
              <h2 className="mb-[13px]">Searching for "{searchKeyword}"</h2>
            )}
            {searchKeyword === "" ? (
              <p>{countFreelancers} members providing service.</p>
            ) : (
              <p>
                {countFreelancers} members providing {searchKeyword} related
                service.
              </p>
            )}
          </div>
          <div className="col-span-12 lg:col-span-8 xl:col-span-9">
            <form className="flex flex-col justify-between lg:justify-end">
              <div className="flex flex-wrap relative items-center justify-end mb-2">
                <div className="flex items-center mr-4">
                  <InputLabel id="service-type-label" className="mb-0">
                    Service Format:
                  </InputLabel>
                  <Select
                    labelId="service-type-label"
                    id="serviceType"
                    value={serviceType}
                    label="ServiceType"
                    onChange={(e) => {
                      localStorage.setItem("serviceType", e.target.value);
                      setServiceType(e.target.value);
                    }}
                  >
                    <MenuItem value={"both"}>Both</MenuItem>
                    <MenuItem value={"in-person"}>In Person</MenuItem>
                    <MenuItem value={"virtual"}>Virtual</MenuItem>
                  </Select>
                </div>
                <div className="relative w-full 2xl:max-w-[560px] xl:max-w-[480px] lg:max-w-[400px]">
                  <input
                    className="px-4 py-3 w-full border-2 border-solid border-zinc-300 rounded-2xl focus-visible:border-zinc-300 outline-zinc-300 text-black max-sm:min-w-[260px]"
                    type="text"
                    name="search"
                    ref={search}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        searchHandler(e);
                      }
                    }}
                    placeholder="What are you looking for?"
                    required
                  ></input>
                  <button
                    onClick={searchHandler}
                    className="btn btn-search absolute top-0.5 right-0.5 inline-block py-2.5 px-8 bg-second rounded-l-none rounded-r-[14px] transition-color duration-300 hover:bg-main outline-none border-none"
                  >
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ fontSize: 20, color: "white" }}
                    />
                  </button>
                </div>
              </div>
              <div className="flex justify-end">
                <div className="flex items-center">
                  <div className="flex items-center mr-4">
                    <input
                      type="radio"
                      id="local"
                      name="location"
                      value="local"
                      onClick={() => {
                        localStorage.setItem("searchLocation", "local");

                        setSearchLocation("local");
                      }}
                    />
                    <label htmlFor="local" className="ml-1 mt-1">
                      Local Area(100 Mile)
                    </label>
                  </div>
                  <div className="flex items-center mr-4">
                    <input
                      type="radio"
                      id="global"
                      name="location"
                      value="global"
                      onClick={() => {
                        localStorage.setItem("searchLocation", "global");

                        setSearchLocation("global");
                      }}
                      defaultChecked
                    />
                    <label htmlFor="global" className="ml-1 mt-1">
                      Global Area
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="custom"
                      name="location"
                      value="custom"
                      onClick={() => {
                        localStorage.setItem("searchLocation", "custom");

                        setSearchLocation("custom");
                      }}
                    />
                    <label htmlFor="global" className="ml-1 mt-1">
                      Custom
                    </label>
                    {searchLocation === "custom" && (
                      <Select
                        labelId="search-radius-label"
                        id="searchRadius"
                        value={searchRadius}
                        label="searchRadius"
                        className="w-28 ml-4"
                        onChange={(e) => {
                          localStorage.setItem("searchRadius", e.target.value);
                          setSearchRadius(e.target.value);
                        }}
                      >
                        <MenuItem value={10}>10 Mile</MenuItem>
                        <MenuItem value={20}>20 Mile</MenuItem>
                        <MenuItem value={30}>30 Mile</MenuItem>
                        <MenuItem value={40}>40 Mile</MenuItem>
                        <MenuItem value={50}>50 Mile</MenuItem>
                        <MenuItem value={60}>60 Mile</MenuItem>
                        <MenuItem value={70}>70 Mile</MenuItem>
                        <MenuItem value={80}>80 Mile</MenuItem>
                        <MenuItem value={90}>90 Mile</MenuItem>
                        <MenuItem value={100}>100 Mile</MenuItem>
                        <MenuItem value={150}>150 Mile</MenuItem>
                        <MenuItem value={200}>200 Mile</MenuItem>
                        <MenuItem value={500}>500 Mile</MenuItem>
                      </Select>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="flex w-full justify-end">
                <div
                  className={
                    searchLocation === "custom" ? "w-full max-w-xl" : "hidden"
                  }
                >
                  <PlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                    autocompletionRequest={{
                      location1,
                    }}
                    selectProps={{
                      onChange: handleSelect,
                    }}
                  />
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="w-full mt-[66px] mb-[106px]">
          <div className="grid grid-cols-3 gap-6 max-lg:grid-cols-2 max-sm:grid-cols-1">
            {freelacnerList.length > 0 ? (
              freelacnerList.map((item, index) => (
                <BasicCard key={`basic-user-${index}`} data={item} />
              ))
            ) : (
              <p>No members found</p>
            )}
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center mb-[70px]">
        <button
          onClick={showMoreHandler}
          className="btn flex justify-center border-none bg-transparent text-[#000] transition-colors duration-300 hover:text-main"
          type="button"
          disabled={loading || freelacnerList.length >= countFreelancers}
        >
          {loading ? (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={80}
              width={80}
            />
          ) : freelacnerList.length >= countFreelancers ? (
            <span>No More</span>
          ) : (
            <>
              <span>Show more</span>
              <FontAwesomeIcon
                icon={faAngleDown}
                style={{ fontSize: 12, color: "dark", marginLeft: "3px" }}
              />
            </>
          )}
        </button>
      </div>
    </main>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  libraries: ["places"],
})(SingleService);
